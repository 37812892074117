import Vue from 'vue'
import App from './App.vue'
import router from './router'
import element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
//import * as echarts from 'echarts';
import * as echarts from 'echarts'
import "./utils/flexible.js"

import {
	instance
} from "./utils/request.js"
import axios from "axios"
import 'font-awesome/css/font-awesome.min.css';
import Print from 'vue-print-nb'
 

Vue.config.productionTip = false
Vue.use(element)
Vue.use(Print);
Vue.prototype.$axios = instance
Vue.prototype.$echarts = echarts

// instance.interceptors.request.use(config => {
// 	//在此处携带token,将token放在请求头中，传给后台
// 	if (window.localStorage.getItem('token')) {
// 		config.headers['x-requested-with'] = window.localStorage.getItem('token')
// 	}
// 	return config;
// }, err => {
// 	console.log(err);
// })

router.beforeEach((to, from, next) => {
	//判断token是否存在
	const token = localStorage.getItem('token')
	//console.log(123)
	console.log(token)
	//若果token不存在应该跳转到登录
	if (!token && to.name !== 'login') {
		next({
			name: 'login'
		})
	} else if (token && to.name === 'login') {
		//token存在跳转到其他页面
		next({
			name: 'index'
		})
	} else {
		next()
	}
})

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')