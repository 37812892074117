<template>
	<div style="border-bottom: 0.01rem solid #F2F6FA;">
		<el-dialog top="35vh" :show-close="false" title="" :visible.sync="dialogVisible" width="7.07rem"
			:before-close="handleClose">
			<div style="width: 7.07rem;height: 3rem;">
				<div style="display: flex;align-items: center;margin-left: 0.43rem;margin-top: 0.51rem;">
					<img style="width: 0.44rem;height: 0.44rem;margin-right: 0.2rem;" src="../assets/image/login3.png"
						alt="" srcset="" />
					<div style="font-weight: bold;font-size: 0.27rem;color: #030A1A;">提示</div>
				</div>
				<div
					style="font-weight: 400;font-size: 0.24rem;color: #7B8391;margin-left: 1.06rem;margin-top: 0.24rem;">
					确定要退出此账号吗？</div>
				<div style="display: flex;justify-content: right;margin-top: 0.57rem;">
					<div style="width: 1.73rem;height: 0.54rem;border: 0.01rem solid #707070;text-align: center;line-height: 0.54rem;font-weight: 400;font-size: 0.2rem;color: #7B8391;"
						@click="dialogVisible = false">取消</div>
					<div style="width: 1.73rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;font-weight: 400;font-size: 0.2rem;color: #fff;margin: 0 0.43rem 0 0.21rem;"
						@click="back1">确定</div>
				</div>
			</div>
			<!-- <div style="text-align: center;position: relative;">
				<img style="width: 3.74rem;height: 2.63rem;" src="../assets/image/identify2.png" alt="" srcset="" />
				<div
					style="font-weight: 400;font-size: 0.22rem;color: #7B8391;position: absolute;bottom: 0rem;left: 50%;margin-left: -2.1rem;">
					*未完成所有项图片的上传，请全部上传后再识别</div>
			</div>
			<div @click="dialogVisible = false"
				style="width: 3.07rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;margin: 0 auto;margin-top: 0.45rem;margin-bottom: 0.3rem;">
				返回
			</div> -->
		</el-dialog>
		<div class="card1">
			<div style="display: flex;align-items: center;">
				<img style="width: 0.29rem;height: 0.29rem;" src="../assets/image/card1.png" alt="" />
				<div class="card_title">智录星</div>
				<div class="card_text">| 速准合一，触手可及。</div>
			</div>
			<div style="display: flex;align-items: center;">
				<div style="position: relative;">
					<img style="width: 5.24rem;height: 0.49rem;" src="../assets/image/card2.png" alt="" />
					<div style="width: 0.82rem;height: 0.32rem;background: #272727;color: #fff;text-align: center;line-height: 0.32rem;position: absolute;right: 0.11rem;top: 0.09rem;border-radius: 0.05rem;">
						联系我们
					</div>
				</div>
				
				<el-dropdown trigger="click" @command="handleCommand">
					<span class="el-dropdown-link"
						style="margin: 0 0.15rem 0 0.23rem;font-weight: 400;font-size: 0.22rem;color: #0F131A;">
						{{name}}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item style="font-size: 0.17rem;" command="a">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<!-- <div style="margin: 0 0.15rem 0 0.23rem;font-weight: 400;font-size: 0.22rem;color: #0F131A;">管理员</div> -->
				<img style="width: 0.52rem;height: 0.52rem;" src="../assets/image/head.png" alt="" />
			</div>
			<!-- <div style="font-size: 20px;font-weight: 700;">全民健身竞赛管理系统</div>
			<div style="display: flex;">
				<div class="head">
					<div class="iconfont icon-geren2"></div>
					<div>你好，{{name}}</div>
				</div>
				<div class="iconfont icon-guanbi"></div>
			</div> -->
		</div>

	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			state: Array,
		},
		data() {
			return {
				name: '',
				dialogVisible: false,
			}
		},
		mounted() {
			this.name = localStorage.getItem('name')
		},
		computed: {
			// //没有子菜单
			// noChildren() {
			// 	return this.state.filter(item => !item.children)
			// },
			// //有子菜单
			// hasChildren() {
			// 	return this.state.filter(item => item.children)
			// },
			// isCollapse() {
			// 	return this.$store.state.tab.isCollapse
			// }
		},
		methods: {
			handleCommand(command) {
				if (command == 'a') {
					this.back()
				}
			},
			handleClose() {
				this.dialogVisible = false
			},
			back1() {
				localStorage.removeItem('token')
				localStorage.removeItem('name')
				//localStorage.clear();
				this.$router.push('/login')
				this.$message({
					type: 'success',
					message: '退出成功!'
				});
			},
			back() {
				this.dialogVisible = true
				// this.$confirm('是否退出此账号', '提示', {
				// 	confirmButtonText: '确定',
				// 	cancelButtonText: '取消',
				// 	type: 'warning'
				// }).then(() => {
				// 	localStorage.removeItem('token')
				// 	localStorage.removeItem('name')
				// 	//localStorage.clear();
				// 	this.$router.push('/login')
				// 	this.$message({
				// 		type: 'success',
				// 		message: '退出成功!'
				// 	});
				// })


			},
		},
	}
</script>

<style scoped lang="less">
	.card1 {
		height: 0.71rem;
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0.41rem;
		
		//justify-content: center;
	}

	.card_title {
		font-weight: bold;
		font-size: 0.24rem;
		color: #030A1A;
		margin-left: 0.1rem;
	}

	.card_text {
		font-weight: 400;
		font-size: 0.2rem;
		color: #7B8391;
		margin-left: 0.15rem;
	}

	/deep/.el-dialog__body {
		padding: 0;
	}
	/deep/.el-dropdown-menu__item .el-menu-item{
		font-size: 0.2rem;
	}
	/deep/.el-dropdown-menu__item:not(.is-disabled):hover{
		color: #2C60F5;
	}
</style>