<template>
	<div v-loading="loading" id="word1" class="wrap">
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>身份证已过期请重新上传</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="" :visible.sync="dialogVisible2" width="15.44rem" :before-close="handleClose">
			<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
				<div id="word" ref="word" class="print-main"></div>

				<div style="display: flex;">
					<div @click="down"
						style="width: 1.73rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.22rem;">
						文档下载</div>
					<div v-print="printOption"
						style="width: 3.07rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
						打印</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="" :visible.sync="dialogVisible1" width="15.44rem" :before-close="handleClose">
			<div class="camera_outer"
				style="width: 14.44rem;display: flex;justify-content: center;flex-direction: column;align-items: center;">
				<video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
				<canvas style="display: none" id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
				<div @click="setImage()"
					style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;">
					点击拍照</div>
			</div>
		</el-dialog>
		<div class="wrap_left">
			<div style="display: flex;justify-content: space-between;align-items: center;">
				<div style="margin-top: 0.2rem;margin-left: 0.38rem;">
					<div style="display: flex;align-items: center;">
						<img style="width: 0.26rem;height: 0.26rem;" src="../assets/image/index2.png" alt=""
							srcset="" />
						<div class="my_template">{{ name }}</div>
					</div>
					<div style="font-weight: 400;font-size: 0.2rem;color: #7B8391;margin-top: 0.06rem;">
						新设备首次启用，适用于车用气瓶使用登记</div>
				</div>
				<div @click="back" style="font-weight: 400;font-size: 0.2rem;color: #7B8391;margin-right: 0.39rem;">《 返回</div>
			</div>
			<div
				style="width: 11.96rem;height: 8.8rem;background: #FFFFFF;border: 0.01rem solid #CCD9F2;margin: 0 auto;margin-top: 0.24rem;">
				<div
					style="display: flex;width: 11.96rem;height: 0.66rem;background: #F2F6FA;align-items: center;padding-left: 0.32rem;box-sizing: border-box;">
					<div v-for="(item, index) in img_list1" @click="select(index)"
						:class="current == index ? 'select1' : 'select'">
						{{ item.title }}
					</div>
				</div>
				<div v-if="current == 0">
					<div class="left">
						<div
							style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
							<div>
								<img @click="big" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details1.png" alt="" />
								<img @click="small" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details2.png" alt="" />
								<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details3.png" alt="" />
							</div>
							<div>
								<img @click="rotateImage"
									style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
									src="../assets/image/identify_details4.png" alt="" />
							</div>
						</div>
						<div style="text-align:center">
							<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
								@mousedown="handleMouseDown">
								<div class="dunpai">
									<img id="oImg" :src="img_list1[current].hege[current1].imageUrl" alt=""
										:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
									<div class="saomiao" v-if="saomiao_show">

									</div>
								</div>
							</div>
						</div>
						<div
							style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
							<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
								<div>请确保边框完整，字体清晰，光线均匀。</div>
								<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
							</div>
							<div style="display: flex;">
								<div @click="open_camera(0)"
									style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
									重新拍照</div>
								<div @click="open_camera1(0)"
									style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
									<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest' class=""
										action="#" :show-file-list="false" :before-upload="beforeAvatarUpload">
										<div>重新上传</div>
									</el-upload>
								</div>
							</div>
						</div>
					</div>
					<div style="display: flex;margin-left: 0.37rem;margin-top: 0.1rem;">
						<div @click="todetails(index)" style="margin-right: 0.13rem;"
							v-for="(item, index) in img_list1[current].hege">
							<div :class="index == current1 ? 'cha1' : 'cha'"
								style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
								<img style="height: 1.07rem;width: auto;" :src="item.imageUrl" alt="" />
							</div>
						</div>
					</div>
				</div>

				<div v-if="current == 1">
					<div v-if="img_list1[current].title == '身份证明'">
						<div class="left">
							<div
								style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
								<div>
									<img @click="big" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details1.png" alt="" />
									<img @click="small" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details2.png" alt="" />
									<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details3.png" alt="" />
								</div>
								<div>
									<img @click="rotateImage"
										style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
										src="../assets/image/identify_details4.png" alt="" />
								</div>
							</div>
							<div style="text-align:center">
								<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
									@mousedown="handleMouseDown">

									<div class="dunpai">
										<img id="oImg" :src="img_list1[current].hege[current1].imageUrl" alt=""
											:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
										<div class="saomiao" v-if="saomiao_show">

										</div>
									</div>
								</div>
							</div>
							<div
								style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
								<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
									<div>请确保边框完整，字体清晰，光线均匀。</div>
									<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
								</div>
								<div style="display: flex;">
									<div @click="open_camera(0)"
										style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
										重新拍照</div>
									<div @click="open_camera1(0)"
										style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
										<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest'
											class="" action="#" :show-file-list="false"
											:before-upload="beforeAvatarUpload">
											<div>重新上传</div>
										</el-upload>
									</div>
								</div>

							</div>
						</div>
						<div style="display: flex;margin-left: 0.37rem;margin-top: 0.1rem;">
							<div @click="todetails(index)" style="margin-right: 0.13rem;"
								v-for="(item, index) in img_list1[current].hege">
								<div :class="index == current1 ? 'cha1' : 'cha'"
									style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
									<img style="height: 1.07rem;width: auto;" :src="item.imageUrl" alt="" />
								</div>
							</div>
						</div>
					</div>
					<div v-if="img_list1[current].title == '营业执照'">
						<div class="left">
							<div
								style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
								<div>
									<img @click="big" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details1.png" alt="" />
									<img @click="small" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details2.png" alt="" />
									<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details3.png" alt="" />
								</div>
								<div>
									<img @click="rotateImage"
										style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
										src="../assets/image/identify_details4.png" alt="" />
								</div>
							</div>
							<div style="text-align:center">
								<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
									@mousedown="handleMouseDown">
									<div class="dunpai">
										<img id="oImg" :src="img_list1[current].imageUrl" alt=""
											:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
										<div class="saomiao" v-if="saomiao_show">
										</div>
									</div>
								</div>
							</div>

							<div
								style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
								<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
									<div>请确保边框完整，字体清晰，光线均匀。</div>
									<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
								</div>
								<div style="display: flex;">
									<div @click="open_camera(1)"
										style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
										重新拍照</div>
									<div @click="open_camera1(1)"
										style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
										<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest'
											class="" action="#" :show-file-list="false"
											:before-upload="beforeAvatarUpload">
											<div>重新上传</div>
										</el-upload>
									</div>
								</div>
							</div>
						</div>

						<div style="display: flex;margin-left: 0.37rem;margin-top: 0.1rem;">
							<div @click="todetails(index)" style="margin-right: 0.13rem;">
								<div :class="current == 1 ? 'cha1' : 'cha'"
									style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
									<img style="height: 1.07rem;width: auto;" :src="img_list1[current].imageUrl"
										alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="current == 2">
					<div class="left">
						<div
							style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
							<div>
								<img @click="big" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details1.png" alt="" />
								<img @click="small" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details2.png" alt="" />
								<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details3.png" alt="" />
							</div>
							<div>
								<img @click="rotateImage"
									style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
									src="../assets/image/identify_details4.png" alt="" />
							</div>
						</div>
						<div style="text-align:center">
							<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
								@mousedown="handleMouseDown">
								<div class="dunpai">
									<img id="oImg" :src="img_list1[current].imageUrl" alt=""
										:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
									<div class="saomiao" v-if="saomiao_show">

									</div>
								</div>
							</div>
						</div>
						<div
							style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
							<div style="font-weight: 400;font-size: 0.21rem;color: #C7CDD8;">
								<div>请确保边框完整，字体清晰，光线均匀。</div>
								<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
							</div>
							<div style="display: flex;">
								<div @click="open_camera(1)"
									style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
									重新拍照</div>
								<div @click="open_camera1(1)"
									style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
									<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest' class=""
										action="#" :show-file-list="false" :before-upload="beforeAvatarUpload">
										<div>重新上传</div>
									</el-upload>
								</div>
							</div>

						</div>
					</div>

					<div style="display: flex;margin-left: 0.37rem;margin-top: 0.1rem;">
						<div @click="todetails(index)" style="margin-right: 0.13rem;">
							<div :class="current == 2 ? 'cha1' : 'cha'"
								style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
								<img style="height: 1.07rem;width: auto;" :src="img_list1[current].imageUrl" alt="" />
							</div>
						</div>
					</div>
				</div>

				<div v-if="current == 3">
					<div class="left">
						<div
							style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
							<div>
								<img @click="big" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details1.png" alt="" />
								<img @click="small" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details2.png" alt="" />
								<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details3.png" alt="" />
							</div>
							<div>
								<img @click="rotateImage"
									style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
									src="../assets/image/identify_details4.png" alt="" />
							</div>
						</div>
						<div style="text-align:center">
							<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
								@mousedown="handleMouseDown">
								<div class="dunpai">
									<img id="oImg" :src="img_list1[current].imageUrl" alt=""
										:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
									<div class="saomiao" v-if="saomiao_show">

									</div>
								</div>
							</div>
						</div>
						<div
							style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
							<div style="font-weight: 400;font-size: 0.21rem;color: #C7CDD8;">
								<div>请确保边框完整，字体清晰，光线均匀。</div>
								<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
							</div>
							<div style="display: flex;">
								<div @click="open_camera(1)"
									style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
									重新拍照</div>
								<div @click="open_camera1(1)"
									style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
									<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest' class=""
										action="#" :show-file-list="false" :before-upload="beforeAvatarUpload">
										<div>重新上传</div>
									</el-upload>
								</div>
							</div>
						</div>
					</div>

					<div style="display: flex;margin-left: 0.37rem;margin-top: 0.1rem;">
						<div @click="todetails(index)" style="margin-right: 0.13rem;">
							<div :class="current == 3 ? 'cha1' : 'cha'"
								style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
								<img style="height: 1.07rem;width: auto;" :src="img_list1[current].imageUrl" alt="" />
							</div>
						</div>
					</div>
				</div>

				<div v-if="current == 4">
					<div class="left">
						<div
							style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
							<div>
								<img @click="big" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details1.png" alt="" />
								<img @click="small" style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details2.png" alt="" />
								<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
									src="../assets/image/identify_details3.png" alt="" />
							</div>
							<div>
								<img @click="rotateImage"
									style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
									src="../assets/image/identify_details4.png" alt="" />
							</div>
						</div>
						<div style="text-align:center">
							<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
								@mousedown="handleMouseDown">
								<div class="dunpai">
									<img id="oImg" :src="img_list1[current].imageUrl" alt=""
										:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
									<div class="saomiao" v-if="saomiao_show">
									</div>
								</div>
							</div>
						</div>
						<div
							style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
							<div style="font-weight: 400;font-size: 0.21rem;color: #C7CDD8;">
								<div>请确保边框完整，字体清晰，光线均匀。</div>
								<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
							</div>
							<div style="display: flex;">
								<div @click="open_camera(1)"
									style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
									重新拍照</div>
								<div @click="open_camera1(1)"
									style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
									<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest' class=""
										action="#" :show-file-list="false" :before-upload="beforeAvatarUpload">
										<div>重新上传</div>
									</el-upload>
								</div>
							</div>
						</div>
					</div>
					<div style="display: flex;margin-left: 0.37rem;margin-top: 0.1rem;">
						<div @click="todetails(index)" style="margin-right: 0.13rem;">
							<div :class="current == 4 ? 'cha1' : 'cha'"
								style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
								<img style="height: 1.07rem;width: auto;" :src="img_list1[current].imageUrl" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="wrap_right">
			<div style="display: flex;justify-content: center;margin-top: 0.26rem;">
				<div @click="down"
					style="width: 1.73rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;margin-right: 0.22rem;">
					文档下载
				</div>
				<div @click="print"
					style="width: 3.07rem;height: 0.54rem;background: #F6990E;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;">
					预览打印
				</div>
			</div>
			<div
				style="width: 6.66rem;height: 8.78rem;background: #FFFFFF;border: 0.01rem solid #CCD9F2;margin: 0 auto;margin-top: 0.26rem;">
				<div style="margin-top: 0.32rem;text-align: center;font-size: 0.19rem;">
					特种设备使用登记表
				</div>
				<div style="margin: 0.21rem 0 0 1.1rem;font-size: 0.15rem;">
					登记类别：新设备首次启用
				</div>
				<div
					style="width: 5.92rem;height: 7.22rem;border: 2px #000 solid;margin: 0 auto;margin-top: 0.1rem;font-size: 0.15rem;">
					<div style="display: flex;">
						<div class="table_left">设备基本情况</div>
						<div class="table_right">
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									设备品种
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[0].车用气瓶" placeholder=""></el-input>
								</div>
								<div
									style="width: 0.82rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									产品名称
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.94rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[0].产品名称" placeholder=""></el-input>
								</div>
							</div>
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									气瓶数量
								</div>
								<div :class="current == 3?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[3].气瓶数量" placeholder=""></el-input>
								</div>
								<div
									style="width: 0.82rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									充装介质
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.94rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[0].充装介质" placeholder=""></el-input>
								</div>
							</div>
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									气瓶公称工作压力
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[0].工作压力" placeholder=""></el-input>
								</div>
								<div
									style="width: 0.82rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									气瓶容积
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.94rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[0].气瓶容积" placeholder=""></el-input>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									制造单位名称
								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									制造日期
								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									产品编号
								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									单位内编号
								</div>
							</div>
							<!-- 四个空行 -->
							<div style="display: flex;">
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[0].制作单位名称" placeholder=""></el-input>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[0].制造日期" placeholder=""></el-input>
								</div>
								<div :class="current == 0?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[0].产品编号" placeholder=""></el-input>
								</div>
								<div :class="current == 3?'brightness':''"
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<el-input v-model="list1[3].单位内编号" placeholder=""></el-input>
								</div>
							</div>
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
							</div>
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
							</div>
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
								<div
									style="width: 1.38rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									施工单位名称
								</div>
								<div v-if="img_list1.length != 5">
									<div :class="current == 3?'brightness':''"
										style="width: 4.17rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
										<el-input v-model="list1[3].安装单位" placeholder=""></el-input>
									</div>
								</div>
								<div v-if="img_list1.length == 5">
									<div :class="current == 4?'brightness':''"
										style="width: 4.17rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
										<el-input v-model="list1[4].安装单位" placeholder=""></el-input>
									</div>
								</div>

							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									监督检验机构名称
								</div>
								<div
									style="width: 4.17rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">

								</div>
							</div>

						</div>
					</div>


					<div style="display: flex;">
						<div class="table_left" style="height: 1.89rem;">设备使用情况</div>
						<div class="table_right">
							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									使用单位名称
								</div>
								<div :class="current == 1?'brightness':''"
									style="width: 4.17rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div v-if="img_list1[1].title == '身份证明'">
										<el-input v-model="list1[1].姓名" placeholder=""></el-input>
									</div>
									<div v-if="img_list1[1].title == '营业执照'">
										<el-input v-model="list1[1].名称" placeholder=""></el-input>
									</div>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.26rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									使用单位地址
								</div>
								<div :class="current == 1?'brightness':''"
									style="width: 4.17rem;height: 0.26rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.26rem;">
									<div v-if="img_list1[1].title == '身份证明'">
										<el-input v-model="list1[1].住址" placeholder=""></el-input>
									</div>
									<div v-if="img_list1[1].title == '营业执照'">
										<el-input v-model="list1[1].住所" placeholder=""></el-input>
									</div>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.44rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;">
									使用单位统一社会信用代码
								</div>
								<div :class="current == 1?'brightness':''"
									style="width: 1.38rem;height: 0.44rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.44rem;">
									<div v-if="img_list1[1].title == '身份证明'">
										<el-input v-model="list1[1].公民身份号码" placeholder=""></el-input>
									</div>
									<div v-if="img_list1[1].title == '营业执照'">
										<el-input v-model="list1[1].统一社会信用代码" placeholder=""></el-input>
									</div>
								</div>
								<div
									style="width: 1.38rem;height: 0.44rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.44rem;">
									邮政编码
								</div>
								<div :class="current == 1?'brightness':''"
									style="width: 1.38rem;height: 0.44rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.44rem;">
									<el-input v-model="list1[1].邮政编码" placeholder=""></el-input>
									<!-- <div v-if="img_list1[1].title == '身份证明'">
										<el-input v-model="list1[1].公民身份号码" placeholder=""></el-input>
									</div>
									<div v-if="img_list1[1].title == '营业执照'">
										<el-input v-model="list1[1].统一社会信用代码" placeholder=""></el-input>
									</div> -->
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									车牌号
								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									<el-input v-model="list1[2].机动车登记编号" placeholder=""></el-input>
								</div>
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									车辆VIN码
								</div>
								<div :class="current == 2?'brightness':''"
									style="width: 1.38rem;height: 0.29rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									<el-input v-model="list1[2].车辆识别代号" placeholder=""></el-input>
								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									投入使用日期
								</div>
								<div :class="current == 3?'brightness':''" 
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									<el-date-picker :clearable="false" style="width: 1.38rem;" format="yyyy 年 MM 月 dd 日"
										value-format="yyyy-MM-dd" v-model="list1[3].投入使用日期" @change="changeTime"
										type="date" placeholder="选择日期">
									</el-date-picker>
								</div>
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									单位固定电话
								</div>
								<div
									style="width: 1.38rem;height: 0.29rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">

								</div>
							</div>

							<div style="display: flex;">
								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									安全管理员
								</div>
								<div v-if="img_list1.length != 5">
									<div :class="current == 3?'brightness':''"
										style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
										<el-input v-model="list1[3].安全管理员" placeholder=""></el-input>
									</div>
								</div>
								<div v-if="img_list1.length == 5">
									<div :class="current == 4?'brightness':''"
										style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
										<el-input v-model="list1[4].安全管理员" placeholder=""></el-input>
									</div>
								</div>

								<div
									style="width: 1.38rem;height: 0.29rem;border-right: 0.01rem solid #787B81;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									移动电话
								</div>
								<div :class="current == 3?'brightness':''"
									style="width: 1.38rem;height: 0.29rem;border-bottom: 0.01rem solid #787B81;text-align: center;line-height: 0.29rem;">
									<el-input v-model="list1[3].移动电话" placeholder=""></el-input>
								</div>
							</div>

						</div>
					</div>

					<div style="width: 5.92rem;height: 1.5rem;border-bottom: 0.01rem solid #787B81;">
						<div style="text-indent: 2em;margin-top: 0.1rem;padding: 0 0.05rem;">
							在此申明：所申报的内容真实；在使用过程中，将严格执行《中华人民共和国特种设备安全法》及相关规定，并且接受特种设备安全管理部门的监督管理。</div>
						<div style="display: flex;margin-top: 0.18rem;">
							<div style="margin-left: 0.5rem;">使用单位填表人员：</div>
							<div style="margin-left: 0.28rem;">(手签)</div>
							<div style="margin-left: 0.15rem;">日期</div>
						</div>
						<div style="display: flex;justify-content: right;margin-top: 0.24rem;">(使用单位公章)</div>
						<div style="display: flex;width: 100%;">
							<div style="display: flex;">
								<div style="margin-left: 0.25rem;">使用单位安全管理人员：</div>
								<div style="margin-left: 0.28rem;">(手签)</div>
								<div style="margin-left: 0.15rem;">日期</div>
							</div>
							<div style="margin-left: 1.92rem;"><span>年</span><span
									style="margin-left: 0.23rem;">月</span><span style="margin-left: 0.23rem;">日</span>
							</div>
						</div>
					</div>
					<div style="width: 5.92rem;height: 1.07rem;">
						<div>说明：</div>
						<div style="display: flex;">
							<div style="margin-left: 0.5rem;">登记机关登记人员：</div>
							<div style="margin-left: 0.15rem;">日期</div>
						</div>
						<div style="display: flex;justify-content: right;margin-top: 0.15rem;">(使用单位公章)</div>
						<div style="display: flex;width: 100%;">
							<div style="display: flex;">
								<div style="margin-left: 0.5rem;">使用登记证编号：</div>
							</div>
							<div style="margin-left: 3.25rem;"><span>年</span><span
									style="margin-left: 0.23rem;">月</span><span style="margin-left: 0.23rem;">日</span>
							</div>
						</div>
					</div>

				</div>
				<div style="margin-left: 0.35rem;margin-top: 0.1rem;">注：本式样适用于车用气瓶使用登记。</div>
			</div>
		</div>
		<!-- <div style="width: 16.97rem;margin: 0 auto;min-width: 16.97rem;">
			<div v-if="!show">
				<div style="display: flex;align-items: center;margin-top: 0.38rem;justify-content: center;">
					<img style="width: 0.46rem;height: 0.46rem;" src="../assets/image/index2.png" alt="" srcset="" />
					<div class="my_template">{{ name }}</div>
				</div>
				<div style="display: flex;justify-content: center;margin-top: 0.26rem;">
					<div v-for="(item, index) in img_list1" @click="select(index)"
						:class="current == index ? 'select1' : 'select'">
						{{ item.title }}
					</div>
				</div>
				<div style="display: flex;justify-content: center;margin-top: 0.26rem;margin-bottom: 0.21rem;">
					<div @click="down"
						style="width: 1.73rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;margin-right: 0.22rem;">
						文档下载
					</div>
					<div @click="print"
						style="width: 3.07rem;height: 0.54rem;background: #F6990E;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;">
						预览打印
					</div>

				</div>
			</div>

			<div v-if="show">
				<div style="display: flex;align-items: center;margin-top: 0.8rem;justify-content: center;">
					<img style="width: 0.46rem;height: 0.46rem;" src="../assets/image/index2.png" alt="" srcset="" />
					<div class="my_template">{{ name }}</div>
				</div>
				<div style="display: flex;justify-content: center;margin-top: 0.26rem;">
					<div v-for="(item, index) in img_list1" @click="select(index)"
						:class="current == index ? 'select1' : 'select'">
						{{ item.title }}
					</div>
				</div>
				<div style="display: flex;justify-content: center;margin-top: 0.49rem;margin-bottom: 0.56rem;">
					<div @click="down"
						style="width: 1.73rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;margin-right: 0.22rem;">
						文档下载
					</div>
					<div @click="print"
						style="width: 3.07rem;height: 0.54rem;background: #F6990E;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;">
						预览打印
					</div>
				</div>
			</div>

			<div v-if="current == 0">
				<div style="width: 16.95rem;height: 9.18rem;border: 0.01rem solid #CCD9F2;background-color: #fff;">
					<div style="width: 16.95rem;height: 0.66rem;background: #F2F6FA;"></div>
					<div
						style="display: flex;justify-content: space-between;margin: 0.13rem 0.21rem 0 0.21rem;position: relative;">
						<div class="left">
							<div
								style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
								<div>
									<img @click="big" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details1.png" alt="" />
									<img @click="small" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details2.png" alt="" />
									<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details3.png" alt="" />
								</div>
								<div>
									<img @click="rotateImage"
										style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
										src="../assets/image/identify_details4.png" alt="" />
								</div>
							</div>
							<div style="text-align:center">
								<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
									@mousedown="handleMouseDown">
									<div class="dunpai">
										<img id="oImg" :src="img_list1[current].hege[current1].imageUrl" alt=""
											:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
										<div class="saomiao" v-if="saomiao_show">

										</div>
									</div>
								</div>
							</div>
							<div
								style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
								<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
									<div>请确保边框完整，字体清晰，光线均匀。</div>
									<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
								</div>
								<div style="display: flex;">
									<div @click="open_camera(0)"
										style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
										重新拍照</div>
									<div @click="open_camera1(0)"
										style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
										<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest'
											class="" action="#" :show-file-list="false"
											:before-upload="beforeAvatarUpload">
											<div>重新上传</div>
										</el-upload>
									</div>
								</div>

							</div>
						</div>
						<div class="right">
							<div
								style="width: 5.12rem;height: 0.59rem;border-bottom: 0.01rem solid #CBD8F2;line-height: 0.59rem;font-weight: bold;font-size: 0.21rem;color: #2468F2;padding-left: 0.3rem;box-sizing: border-box;">
								识别结果：
							</div>
							<div style="overflow-y: auto;height: 6.37rem;">
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">设备品种：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].车用气瓶" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">产品名称：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].产品名称" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">充装介质：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].充装介质" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">公称工作压力：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].工作压力" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">气瓶容积：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].气瓶容积" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">制作单位名称：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].制作单位名称" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">制造日期：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].制造日期" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">产品编号：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].产品编号" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="height: 0.2rem;"></div>
							</div>
						</div>
					</div>
					<div style="display: flex;margin-left: 0.21rem;margin-top: 0.15rem;">
						<div @click="todetails(index)" style="margin-right: 0.13rem;"
							v-for="(item, index) in img_list1[current].hege">
							<div :class="index == current1 ? 'cha1' : 'cha'"
								style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
								<img style="height: 1.07rem;width: auto;" :src="item.imageUrl" alt="" />
							</div>
						</div>
					</div>


				</div>
			</div>
			<div v-if="current == 1">
				<div v-if="img_list1[current].title == '身份证明'"
					style="width: 16.95rem;height: 9.18rem;border: 0.01rem solid #CCD9F2;background-color: #fff;">
					<div style="width: 16.95rem;height: 0.66rem;background: #F2F6FA;"></div>
					<div
						style="display: flex;justify-content: space-between;margin: 0.13rem 0.21rem 0 0.21rem;position: relative;">
						<div class="left">
							<div
								style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
								<div>
									<img @click="big" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details1.png" alt="" />
									<img @click="small" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details2.png" alt="" />
									<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details3.png" alt="" />
								</div>
								<div>
									<img @click="rotateImage"
										style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
										src="../assets/image/identify_details4.png" alt="" />
								</div>
							</div>
							<div style="text-align:center">
								<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
									@mousedown="handleMouseDown">

									<div class="dunpai">
										<img id="oImg" :src="img_list1[current].hege[current1].imageUrl" alt=""
											:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
										<div class="saomiao" v-if="saomiao_show">

										</div>
									</div>
								</div>
							</div>
							<div
								style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
								<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
									<div>请确保边框完整，字体清晰，光线均匀。</div>
									<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
								</div>
								<div style="display: flex;">
									<div @click="open_camera(0)"
										style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
										重新拍照</div>
									<div @click="open_camera1(0)"
										style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
										<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest'
											class="" action="#" :show-file-list="false"
											:before-upload="beforeAvatarUpload">
											<div>重新上传</div>
										</el-upload>
									</div>
								</div>

							</div>
						</div>
						<div class="right">
							<div
								style="width: 5.12rem;height: 0.59rem;border-bottom: 0.01rem solid #CBD8F2;line-height: 0.59rem;font-weight: bold;font-size: 0.21rem;color: #2468F2;padding-left: 0.3rem;box-sizing: border-box;">
								识别结果：
							</div>
							<div style="overflow-y: auto;height: 6.37rem;">
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">姓名：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].姓名" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">住址：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].住址" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">公民身份号码：
									</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].公民身份号码" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">邮政编码：
									</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].邮政编码" placeholder="请输入内容"></el-input>
									</div>
								</div>

								<div style="height: 0.2rem;"></div>
							</div>
						</div>
					</div>
					<div style="display: flex;margin-left: 0.21rem;margin-top: 0.15rem;">
						<div @click="todetails(index)" style="margin-right: 0.13rem;"
							v-for="(item, index) in img_list1[current].hege">
							<div :class="index == current1 ? 'cha1' : 'cha'"
								style="width: 1.73rem;height: 1.07rem;background: #EBEEF2;text-align: center;">
								<img style="height: 1.07rem;width: auto;" :src="item.imageUrl" alt="" />
							</div>
						</div>
					</div>


				</div>




				<div v-if="img_list1[current].title == '营业执照'"
					style="width: 16.95rem;height: 7.96rem;border: 0.01rem solid #CCD9F2;background-color: #fff;">
					<div style="width: 16.95rem;height: 0.66rem;background: #F2F6FA;"></div>
					<div
						style="display: flex;justify-content: space-between;margin: 0.13rem 0.21rem 0 0.21rem;position: relative;">
						<div class="left">
							<div
								style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
								<div>
									<img @click="big" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details1.png" alt="" />
									<img @click="small" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details2.png" alt="" />
									<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details3.png" alt="" />
								</div>
								<div>
									<img @click="rotateImage"
										style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
										src="../assets/image/identify_details4.png" alt="" />
								</div>
							</div>
							<div style="text-align:center">
								<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
									@mousedown="handleMouseDown">
									<div class="dunpai">
										<img id="oImg" :src="img_list1[current].imageUrl" alt=""
											:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
										<div class="saomiao" v-if="saomiao_show">
										</div>
									</div>
								</div>
							</div>

							<div
								style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
								<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
									<div>请确保边框完整，字体清晰，光线均匀。</div>
									<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
								</div>
								<div style="display: flex;">
									<div @click="open_camera(1)"
										style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
										重新拍照</div>
									<div @click="open_camera1(1)"
										style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
										<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest'
											class="" action="#" :show-file-list="false"
											:before-upload="beforeAvatarUpload">
											<div>重新上传</div>
										</el-upload>
									</div>
								</div>
							</div>
						</div>
						<div class="right">
							<div
								style="width: 5.12rem;height: 0.59rem;border-bottom: 0.01rem solid #CBD8F2;line-height: 0.59rem;font-weight: bold;font-size: 0.21rem;color: #2468F2;padding-left: 0.3rem;box-sizing: border-box;">
								识别结果：
							</div>
							<div v-if="img_list1[current].title == '身份证明'">
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">姓名：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].姓名" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">住址：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].住址" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">公民身份号码：
									</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].公民身份号码" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">邮政编码：
									</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].邮政编码" placeholder="请输入内容"></el-input>
									</div>
								</div>
							</div>
							<div v-if="img_list1[current].title == '营业执照'">
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">营业执照名称：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].名称" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">营业执照住所：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].住所" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">单位统一社会信用代码：
									</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].统一社会信用代码" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">邮政编码：
									</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].邮政编码" placeholder="请输入内容"></el-input>
									</div>
								</div>
							</div>


						</div>
					</div>
				</div>
			</div>
			<div v-if="current == 2">
				<div style="width: 16.95rem;height: 7.96rem;border: 0.01rem solid #CCD9F2;background-color: #fff;">
					<div style="width: 16.95rem;height: 0.66rem;background: #F2F6FA;"></div>
					<div
						style="display: flex;justify-content: space-between;margin: 0.13rem 0.21rem 0 0.21rem;position: relative;">
						<div class="left">
							<div
								style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
								<div>
									<img @click="big" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details1.png" alt="" />
									<img @click="small" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details2.png" alt="" />
									<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details3.png" alt="" />
								</div>
								<div>
									<img @click="rotateImage"
										style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
										src="../assets/image/identify_details4.png" alt="" />
								</div>
							</div>
							<div style="text-align:center">
								<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
									@mousedown="handleMouseDown">
									<div class="dunpai">
										<img id="oImg" :src="img_list1[current].imageUrl" alt=""
											:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
										<div class="saomiao" v-if="saomiao_show">

										</div>
									</div>
								</div>
							</div>
							<div
								style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
								<div style="font-weight: 400;font-size: 0.21rem;color: #C7CDD8;">
									<div>请确保边框完整，字体清晰，光线均匀。</div>
									<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
								</div>
								<div style="display: flex;">
									<div @click="open_camera(1)"
										style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
										重新拍照</div>
									<div @click="open_camera1(1)"
										style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
										<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest'
											class="" action="#" :show-file-list="false"
											:before-upload="beforeAvatarUpload">
											<div>重新上传</div>
										</el-upload>
									</div>
								</div>

							</div>
						</div>
						<div class="right">
							<div
								style="width: 5.12rem;height: 0.59rem;border-bottom: 0.01rem solid #CBD8F2;line-height: 0.59rem;font-weight: bold;font-size: 0.21rem;color: #2468F2;padding-left: 0.3rem;box-sizing: border-box;">
								识别结果：
							</div>
							<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
								<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">车牌号：</div>
								<div style="width: 4.15rem;margin-top: 0.12rem;">
									<el-input v-model="list1[current].机动车登记编号" placeholder="请输入内容"></el-input>
								</div>
							</div>
							<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
								<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">车辆VIN码：</div>
								<div style="width: 4.15rem;margin-top: 0.12rem;">
									<el-input v-model="list1[current].车辆识别代号" placeholder="请输入内容"></el-input>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div v-if="current == 3">
				<div style="width: 16.95rem;height: 7.96rem;border: 0.01rem solid #CCD9F2;background-color: #fff;">
					<div style="width: 16.95rem;height: 0.66rem;background: #F2F6FA;"></div>
					<div
						style="display: flex;justify-content: space-between;margin: 0.13rem 0.21rem 0 0.21rem;position: relative;">
						<div class="left">
							<div
								style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
								<div>
									<img @click="big" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details1.png" alt="" />
									<img @click="small" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details2.png" alt="" />
									<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details3.png" alt="" />
								</div>
								<div>
									<img @click="rotateImage"
										style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
										src="../assets/image/identify_details4.png" alt="" />
								</div>
							</div>
							<div style="text-align:center">
								<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
									@mousedown="handleMouseDown">
									<div class="dunpai">
										<img id="oImg" :src="img_list1[current].imageUrl" alt=""
											:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
										<div class="saomiao" v-if="saomiao_show">

										</div>
									</div>

								</div>
							</div>
							<div
								style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
								<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
									<div>请确保边框完整，字体清晰，光线均匀。</div>
									<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
								</div>
								<div style="display: flex;">
									<div @click="open_camera(1)"
										style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
										重新拍照</div>
									<div @click="open_camera1(1)"
										style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
										<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest'
											class="" action="#" :show-file-list="false"
											:before-upload="beforeAvatarUpload">
											<div>重新上传</div>
										</el-upload>
									</div>
								</div>
							</div>
						</div>
						<div class="right">
							<div
								style="width: 5.12rem;height: 0.59rem;border-bottom: 0.01rem solid #CBD8F2;line-height: 0.59rem;font-weight: bold;font-size: 0.21rem;color: #2468F2;padding-left: 0.3rem;box-sizing: border-box;">
								识别结果：
							</div>
							<div v-if="img_list1.length != 5" style="overflow-y: auto;height: 6.37rem;">
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">单位内编号：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].单位内编号" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">气瓶数量：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].气瓶数量" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">安装单位：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].安装单位" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">安全管理员：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].安全管理员" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">投入使用日期：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-date-picker style="width: 4.15rem;" format="yyyy 年 MM 月 dd 日"
											value-format="yyyy-MM-dd" v-model="list1[current].投入使用日期"
											@change="changeTime" type="date" placeholder="选择日期">
										</el-date-picker>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">移动电话：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].移动电话" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="height: 0.2rem;"></div>
							</div>

							<div v-if="img_list1.length == 5" style="overflow-y: auto;height: 6.37rem;">
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">安装单位：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].安装单位" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">安全管理员：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].安全管理员" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="height: 0.2rem;"></div>
							</div>

						</div>
					</div>
				</div>
			</div>


			<div v-if="current == 4">
				<div style="width: 16.95rem;height: 7.96rem;border: 0.01rem solid #CCD9F2;background-color: #fff;">
					<div style="width: 16.95rem;height: 0.66rem;background: #F2F6FA;"></div>
					<div
						style="display: flex;justify-content: space-between;margin: 0.13rem 0.21rem 0 0.21rem;position: relative;">
						<div class="left">
							<div
								style="position: absolute;top: 0;z-index: 99;display: flex;justify-content: space-between;width: 100%;">
								<div>
									<img @click="big" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details1.png" alt="" />
									<img @click="small" style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details2.png" alt="" />
									<img @click="restoration " style="width: 0.63rem;height: 0.63rem;"
										src="../assets/image/identify_details3.png" alt="" />
								</div>
								<div>

									<img @click="rotateImage"
										style="width: 0.49rem;height: 0.49rem;margin: 0.07rem 0.11rem 0 0;"
										src="../assets/image/identify_details4.png" alt="" />
								</div>
							</div>
							<div style="text-align:center">
								<div class="productDrawing_Img" ref='previewImgDiv' @mousewheel="handerPictu"
									@mousedown="handleMouseDown">
									<div class="dunpai">
										<img id="oImg" :src="img_list1[current].imageUrl" alt=""
											:style="{ width: imgWidth, height: imgHeight,transform: `scale(1) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg`}" />
										<div class="saomiao" v-if="saomiao_show">

										</div>
									</div>
								</div>
							</div>
							<div
								style="width: 11.22rem;height: 0.77rem;background: rgba(0,0,0,0.5);position: absolute;bottom: 0;display: flex;align-items: center;justify-content: space-between;padding: 0 0.18rem 0 0.4rem;box-sizing: border-box;">
								<div style="font-weight: 400;font-size: 0.17rem;color: #C7CDD8;">
									<div>请确保边框完整，字体清晰，光线均匀。</div>
									<div>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</div>
								</div>
								<div style="display: flex;">
									<div @click="open_camera(1)"
										style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;margin-right: 0.1rem;">
										重新拍照</div>
									<div @click="open_camera1(1)"
										style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
										<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest'
											class="" action="#" :show-file-list="false"
											:before-upload="beforeAvatarUpload">
											<div>重新上传</div>
										</el-upload>
									</div>
								</div>
							</div>
						</div>
						<div class="right">
							<div
								style="width: 5.12rem;height: 0.59rem;border-bottom: 0.01rem solid #CBD8F2;line-height: 0.59rem;font-weight: bold;font-size: 0.21rem;color: #2468F2;padding-left: 0.3rem;box-sizing: border-box;">
								识别结果：
							</div>
							<div style="overflow-y: auto;height: 6.37rem;">
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">安装单位：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].安装单位" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="margin-left: 0.3rem;margin-top: 0.21rem;">
									<div style="font-weight: 400;font-size: 0.21rem;color: #333333;">安全管理员：</div>
									<div style="width: 4.15rem;margin-top: 0.12rem;">
										<el-input v-model="list1[current].安全管理员" placeholder="请输入内容"></el-input>
									</div>
								</div>
								<div style="height: 0.2rem;"></div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	//万物识别
	import '../assets/icon/iconfont.css'
	//import printJS from 'print-js'
	import BlobUtil from 'blob-util';
	export default {
		// components: {
		// 	VZoomBox
		// },
		data() {
			return {
				brightness_list: ['产品名称', '充装介质', '工作压力', '气瓶容积', '制作单位名称', '制造日期', '产品编号'],
				isexpire: false,
				saomiao_show: false,
				rotation: 0,
				imgWidth: "6.96rem",
				imgHeight: "auto",
				scale: 1,
				translateX: 0,
				translateY: 0,
				loading: false,
				dialogVisible2: false,
				printOption: {
					id: 'word', // 打印元素的id 不需要携带#号
					//popTitle: '工作任务' // 页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
				},
				iframeUrl: '',
				current2: 0,
				list1: this.$route.query.list1,
				list: [{
					list: ["产品名称", "充装介质", "工作压力", "气瓶容积", "制作单位名称", "制造日期", "产品编号"],
				}, {
					list: ["个人或营业执照名称", "身份证地址或营业执照住所", "身份证号或使用单位统一社会信用代码"],
				}, {
					list: ["机动车登记编号", "车辆识别代号"],
				}, {
					list: ["气瓶数量", "安装单位", "安全管理员"],
				}, {
					list: ["安装单位", "安全管理员"],
				}],

				show1: false,
				videoWidth: 960,
				videoHeight: 600,
				imgSrc: "",
				thisCancas: null,
				thisContext: null,
				thisVideo: null,
				openVideo: false,
				dialogVisible1: false,
				dialogVisible: false,
				camera: false,

				input: '',
				show: false,
				screenHeight: '',
				current: 0,
				current1: 0,
				img_list1: JSON.parse(this.$route.query.img_list1),
				name: this.$route.query.name,
			}
		},
		created() {
			console.log(this.$route.query.img_list)
			console.log(this.$route.query.img_list1)
			console.log(this.$route.query.name)

			console.log(this.$route.query.list1)
			//this.$route.query.img_list1.push()
		},
		mounted() {
			this.screenHeight = document.body.clientHeight
			window.onresize = () => {
				return (() => {
					this.screenHeight = document.body.clientHeight
				})()
			}
			this.dis_certificate_api()
			//this.loading = true
			this.saomiao_show = true
		},
		watch: {
			screenHeight: {
				handler: function(val, oldVal) {
					if (val < 1080) {
						console.log('屏幕宽度小于1080px')
						this.show = false
					} else {
						console.log('屏幕宽度大于1080px')

						if (this.current == 0 || this.current == 1) {
							this.show = false
						} else {
							this.show = true
						}
					}
				}
				//immediate: true
			},
		},
		computed: {

		},
		methods: {
			back(){
				this.$router.push('/identify')
			},
			rotateImage() {
				console.log(123)
				this.rotation += 90;
				this.$forceUpdate();
			},
			big() {
				const img = document.getElementById("oImg");
				this.imgWidth = img.offsetWidth || img.width || img.clientWidth;
				this.imgHeight = img.offsetHeight || img.height || img.clientHeight;
				console.log(111)
				this.imgWidth = `${this.imgWidth + 20}px`;
				this.imgHeight = `${this.imgHeight + 20}px`;
			},
			small() {
				const img = document.getElementById("oImg");
				this.imgWidth = img.offsetWidth || img.width || img.clientWidth;
				this.imgHeight = img.offsetHeight || img.height || img.clientHeight;
				console.log(222)
				this.imgWidth = `${this.imgWidth - 20}px`;
				this.imgHeight = `${this.imgHeight - 20}px`;
			},
			restoration() {
				this.imgWidth = "6.96rem"
				this.imgHeight = "auto"
				this.translateX = 0
				this.translateY = 0
				this.rotation = 0
			},
			handerPictu(e) {
				console.log(123)
				const img = document.getElementById("oImg");
				console.log(img.offsetWidth, img.width, img.clientWidth);
				this.imgWidth = img.offsetWidth || img.width || img.clientWidth;
				this.imgHeight = img.offsetHeight || img.height || img.clientHeight;
				if (e.deltaY > 0) {
					console.log("鼠标向下滚动，图片缩小");
					this.imgWidth = `${this.imgWidth - 20}px`;
					this.imgHeight = `${this.imgHeight - 20}px`;
				} else {
					console.log("鼠标向上滚动，图片放大");
					this.imgWidth = `${this.imgWidth + 20}px`;
					this.imgHeight = `${this.imgHeight + 20}px`;
				}
				//   this.imgWidth = `${this.imgWidth}px`;
				console.log(this.imgWidth, this.imgHeight, "hou");
			},
			handleMousewheel(event) {
				this.scale = this.scale + event.wheelDelta / 1200
				return false
			},
			handleMouseDown(event) {

				event.preventDefault()
				if (event.target.tagName !== 'IMG') {
					return false
				}
				let div = this.$refs.previewImgDiv
				let originX = event.screenX
				let originY = event.screenY
				let translateX = this.translateX
				let translateY = this.translateY
				const move = (e) => {
					let afterX = e.screenX
					let afterY = e.screenY
					this.translateX = translateX + (afterX - originX) / this.scale
					this.translateY = translateY + (afterY - originY) / this.scale
				}
				div.addEventListener('mousemove', move)
				div.addEventListener('mouseup', () => {
					div.removeEventListener('mousemove', move)
				})
			},
			changeTime() {
				console.log(this.list1[1].投入使用日期)
			},
			print() {
				console.log(222)
				if (this.isexpire) {
					this.$message({
						message: '身份证已过期请重新上传',
						type: 'error'
					});
				} else {
					const docx = require("docx-preview");
					var _this = this
					this.dialogVisible2 = true
					if (this.img_list1[1].title == '身份证明') {
						console.log(44)
						if (_this.img_list1.length >= 5) {
							console.log(333)
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': this.list1[0].工作压力,
								'气瓶容积': this.list1[0].气瓶容积,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,
								'单位内编号': this.list1[3].单位内编号,
								'气瓶数量': this.list1[3].气瓶数量,
								'安装单位': this.list1[4].安装单位,
								'安全管理员': this.list1[4].安全管理员,
								'投入使用日期': this.list1[3].投入使用日期,
								'移动电话': this.list1[3].移动电话,

								'姓名': this.list1[1].姓名,
								'住址': this.list1[1].住址,
								'公民身份号码': this.list1[1].公民身份号码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[2].机动车登记编号,
								'车辆识别代号': this.list1[2].车辆识别代号,
							}
						} else {
							console.log(123)
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': this.list1[0].工作压力,
								'气瓶容积': this.list1[0].气瓶容积,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,
								'单位内编号': this.list1[3].单位内编号,
								'气瓶数量': this.list1[3].气瓶数量,
								'安装单位': this.list1[3].安装单位,
								'安全管理员': this.list1[3].安全管理员,
								'投入使用日期': this.list1[3].投入使用日期,
								'移动电话': this.list1[3].移动电话,

								'姓名': this.list1[1].姓名,
								'住址': this.list1[1].住址,
								'公民身份号码': this.list1[1].公民身份号码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[2].机动车登记编号,
								'车辆识别代号': this.list1[2].车辆识别代号,
							}
						}

					} else if (this.img_list1[1].title == '营业执照') {
						if (_this.img_list1.length >= 5) {
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': this.list1[0].工作压力,
								'气瓶容积': this.list1[0].气瓶容积,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,
								'单位内编号': this.list1[3].单位内编号,
								'气瓶数量': this.list1[3].气瓶数量,
								'安装单位': this.list1[4].安装单位,
								'安全管理员': this.list1[4].安全管理员,
								'投入使用日期': this.list1[3].投入使用日期,
								'移动电话': this.list1[3].移动电话,

								'名称': this.list1[1].名称,
								'住址': this.list1[1].住址,
								'统一社会信用代码': this.list1[1].统一社会信用代码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[2].机动车登记编号,
								'车辆识别代号': this.list1[2].车辆识别代号,
							}
						} else {
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': this.list1[0].工作压力,
								'气瓶容积': this.list1[0].气瓶容积,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,
								'单位内编号': this.list1[3].单位内编号,
								'气瓶数量': this.list1[3].气瓶数量,
								'安装单位': this.list1[3].安装单位,
								'安全管理员': this.list1[3].安全管理员,
								'投入使用日期': this.list1[3].投入使用日期,
								'移动电话': this.list1[3].移动电话,

								'名称': this.list1[1].名称,
								'住址': this.list1[1].住址,
								'统一社会信用代码': this.list1[1].统一社会信用代码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[2].机动车登记编号,
								'车辆识别代号': this.list1[2].车辆识别代号,
							}
						}

					}

					_this.$axios.post('daochu_word_api', params, {
						responseType: 'blob',
					}).then(function(res) {
						const blob = new Blob([res.data]);
						//加载完成后，调打印预览接口
						docx.renderAsync(blob, _this.$refs.word, null, {
							className: "docx", // 默认和文档样式类的类名/前缀
							inWrapper: false, // 启用围绕文档内容渲染包装器
							ignoreWidth: false, // 禁止页面渲染宽度
							ignoreHeight: false, // 禁止页面渲染高度
							ignoreFonts: false, // 禁止字体渲染
							breakPages: true, // 在分页符上启用分页
							ignoreLastRenderedPageBreak: true, //禁用lastRenderedPageBreak元素的分页
							experimental: false, //启用实验性功能（制表符停止计算）
							trimXmlDeclaration: true, //如果为真，xml声明将在解析之前从xml文档中删除
							debug: false, // 启用额外的日志记录
						})
						// .then(
						// setTimeout(() => {
						// 	_this.print1(document.getElementsByClassName('docx')[0]) //需要打印的div
						// 	setTimeout(_this.hide) //关闭加载中
						// }, 1000)
						// _this.print1(_this.$refs.word)
						// )
					})
				}

			},
			print1() {

				// // 获取要打印的内容的 DOM 元素
				// const printContent = document.getElementById('word');
				// // 创建一个新的窗口用于打印
				// const printWindow = window.open('', '', 'width=1000,height=600');
				// // 将要打印的内容添加到新窗口中
				// printWindow.document.open();
				// printWindow.document.write(`
				//       <html>
				//         <head>
				//           <title>打印预览</title>
				//         </head>
				//         <body>
				//           ${printContent.innerHTML}
				//         </body>
				// 		<style scoped media="print">
				// 		    /*指定打印的分页*/
				// 		   /deep/ section{
				// 		       page-break-after:always;
				// 		   }

				// 		   /*避免出现空白尾页*/
				// 		   html,body,#app{
				// 		       height: 98% !important;
				// 		       margin: 0px;
				// 		       padding: 0px;
				// 		   }
				// 		</style>
				//       </html>
				//     `);
				// printWindow.document.close();
				// // 使用新窗口的打印功能
				// printWindow.print();
				// //关闭新窗口
				// printWindow.close();
			},
			down() {
				var _this = this
				if (this.isexpire) {
					this.$message({
						message: '身份证已过期请重新上传',
						type: 'error'
					});
				} else {
					if (this.img_list1[1].title == '身份证明') {
						if (_this.img_list1.length >= 5) {
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': this.list1[0].工作压力,
								'气瓶容积': this.list1[0].气瓶容积,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,
								'单位内编号': this.list1[3].单位内编号,
								'气瓶数量': this.list1[3].气瓶数量,
								'安装单位': this.list1[4].安装单位,
								'安全管理员': this.list1[4].安全管理员,
								'移动电话': this.list1[3].移动电话,

								'姓名': this.list1[1].姓名,
								'住址': this.list1[1].住址,
								'公民身份号码': this.list1[1].公民身份号码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[2].机动车登记编号,
								'车辆识别代号': this.list1[2].车辆识别代号,
							}
						} else {
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': this.list1[0].工作压力,
								'气瓶容积': this.list1[0].气瓶容积,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,
								'单位内编号': this.list1[3].单位内编号,
								'气瓶数量': this.list1[3].气瓶数量,
								'安装单位': this.list1[3].安装单位,
								'安全管理员': this.list1[3].安全管理员,
								'移动电话': this.list1[3].移动电话,

								'姓名': this.list1[1].姓名,
								'住址': this.list1[1].住址,
								'公民身份号码': this.list1[1].公民身份号码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[2].机动车登记编号,
								'车辆识别代号': this.list1[2].车辆识别代号,
							}
						}

					} else if (this.img_list1[1].title == '营业执照') {
						if (_this.img_list1.length >= 5) {
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': this.list1[0].工作压力,
								'气瓶容积': this.list1[0].气瓶容积,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,

								'单位内编号': this.list1[3].单位内编号,
								'气瓶数量': this.list1[3].气瓶数量,
								'安装单位': this.list1[4].安装单位,
								'安全管理员': this.list1[4].安全管理员,
								'移动电话': this.list1[3].移动电话,

								'名称': this.list1[1].名称,
								'住所': this.list1[1].住所,
								'统一社会信用代码': this.list1[1].统一社会信用代码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[2].机动车登记编号,
								'车辆识别代号': this.list1[2].车辆识别代号,
							}
						} else {
							var params = {
								'车用气瓶': this.list1[0].车用气瓶,
								'产品名称': this.list1[0].产品名称,
								'充装介质': this.list1[0].充装介质,
								'工作压力': this.list1[0].工作压力,
								'气瓶容积': this.list1[0].气瓶容积,
								'制作单位名称': this.list1[0].制作单位名称,
								'制造日期': this.list1[0].制造日期,
								'产品编号': this.list1[0].产品编号,

								'单位内编号': this.list1[3].单位内编号,
								'气瓶数量': this.list1[3].气瓶数量,
								'安装单位': this.list1[3].安装单位,
								'安全管理员': this.list1[3].安全管理员,
								'移动电话': this.list1[3].移动电话,

								'名称': this.list1[1].名称,
								'住所': this.list1[1].住所,
								'统一社会信用代码': this.list1[1].统一社会信用代码,
								'邮政编码': this.list1[1].邮政编码,

								'机动车登记编号': this.list1[2].机动车登记编号,
								'车辆识别代号': this.list1[2].车辆识别代号,
							}
						}

					}

					_this.$axios.post('daochu_word_api', params, {
						responseType: 'blob',
					}).then(function(res) {
						console.log(res)
						const blob = new Blob([res.data]);
						let downloads = document.createElement("a");
						let href = window.URL.createObjectURL(blob);
						downloads.href = href;
						downloads.download = 'cccc.docx';
						document.body.appendChild(downloads);
						downloads.click();
						document.body.removeChild(downloads);
						window.URL.revokeObjectURL(href);
					})
				}

			},
			dis_certificate_api() {
				var _this = this
				let formData = new FormData()
				if (this.current2 == 0) {
					console.log(123)
					for (var i = 0; i <= this.img_list1[0].hege.length - 1; i++) {
						//img.push(this.img_list1[0].hege[i].imagePath)
						console.log(this.img_list1[0])
						var file = _this.dataURLtoFile(this.img_list1[0].hege[i].imagePath, 143)
						//var file = this.img_list1[0].hege[i].imagePath
						formData.append('img', file)
					}
				} else if (this.current2 == 1) {
					if (this.img_list1[1].title == '身份证明') {
						for (var i = 0; i <= this.img_list1[1].hege.length - 1; i++) {
							//img.push(this.img_list1[0].hege[i].imagePath)
							var file = _this.dataURLtoFile(this.img_list1[1].hege[i].imagePath, 143)
							//var file = this.img_list1[0].hege[i].imagePath
							formData.append('img', file)
						}
					} else {
						var file = _this.dataURLtoFile(this.img_list1[this.current2].imagePath, 143)
						formData.append('img', file)
					}

				} else {
					var file = _this.dataURLtoFile(this.img_list1[this.current2].imagePath, 143)
					formData.append('img', file)
				}
				if (this.img_list1[this.current2].title == '身份证明') {
					this.list[this.current2].list = ["姓名", "住址", "公民身份号码", '有效期限']
				} else if (this.img_list1[this.current2].title == '营业执照') {
					this.list[this.current2].list = ["名称", "住所", "统一社会信用代码"]
				}
				if (this.img_list1.length >= 5) {
					this.list[3].list = ['气瓶数量']
				}
				formData.append('name_list', this.list[this.current2].list)
				_this.$axios.post('dis_certificate_api', formData).then(function(res) {
					console.log(res)
					_this.saomiao_show = false
					if(res.data.data == '请重新识别'){
						_this.current2 = _this.current2 + 1
						console.log(_this.list1)
						_this.$forceUpdate();
						if (_this.current2 > _this.img_list1.length - 1) {
							_this.current2 = 0
							//_this.loading = false
						} else {
							_this.dis_certificate_api()
						}
						//_this.dis_certificate_api()
						// _this.$message({
						// 	message: '身份证已过期请重新上传',
						// 	type: 'error'
						// });
					}else{
						//_this.loading = false
						// list: [{
						// 	list: ["产品名称", "充装介质", "公称工作压力", "气瓶容积", "制作单位名称", "制造日期", "产品编号"],
						// }, {
						// 	list: ["气瓶数量", "安装单位", "安全管理员"],
						// }, {
						// 	list: ["个人或营业执照名称", "身份证地址或营业执照住所", "身份证号或使用单位统一社会信用代码"],
						// }, {
						// 	list: ["车牌号", "车辆识别代号"],
						// }, ],
						if (_this.current2 == 0) {
							_this.list1[_this.current2].产品名称 = res.data.产品名称
							_this.list1[_this.current2].充装介质 = res.data.充装介质
							_this.list1[_this.current2].工作压力 = res.data.工作压力
							_this.list1[_this.current2].气瓶容积 = res.data.气瓶容积
							_this.list1[_this.current2].制作单位名称 = res.data.制作单位名称
							_this.list1[_this.current2].制造日期 = res.data.制造日期
							_this.list1[_this.current2].产品编号 = res.data.产品编号
						} else if (_this.current2 == 1) {
							if (_this.img_list1[_this.current2].title == '身份证明') {
								var data = res.data.有效期限
								let currentDate = new Date();
								let year = currentDate.getFullYear();
								let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 注意月份从0开始，需要加1
								let day = currentDate.getDate();
								var data1 = year + "." + month + "." + day
								// if (new Date(data1) < new Date(data.substring(11))) {
								// 	console.log('没过期')
								// 	_this.isexpire = false
								// } else {
								// 	_this.isexpire = true
								// 	_this.dialogVisible = true
								// }
								_this.list1[_this.current2].姓名 = res.data.姓名
								_this.list1[_this.current2].住址 = res.data.住址
								_this.list1[_this.current2].公民身份号码 = res.data.公民身份号码
							} else if (_this.img_list1[_this.current2].title == '营业执照') {
								_this.list1[_this.current2].名称 = res.data.名称
								_this.list1[_this.current2].住所 = res.data.住所
								_this.list1[_this.current2].统一社会信用代码 = res.data.统一社会信用代码
							}
						} else if (_this.current2 == 2) {
							_this.list1[_this.current2].机动车登记编号 = res.data.机动车登记编号
							_this.list1[_this.current2].车辆识别代号 = res.data.车辆识别代号
						} else if (_this.current2 == 3) {
							if (_this.img_list1.length >= 5) {
								_this.list1[_this.current2].气瓶数量 = res.data.气瓶数量
							} else {
								_this.list1[_this.current2].气瓶数量 = res.data.气瓶数量
								_this.list1[_this.current2].安装单位 = res.data.安装单位
								_this.list1[_this.current2].安全管理员 = res.data.安全管理员
							}
						} else if (_this.current2 == 4) {
							_this.list1[_this.current2].安装单位 = res.data.安装单位
							_this.list1[_this.current2].安全管理员 = res.data.安全管理员
						}
						_this.current2 = _this.current2 + 1
						console.log(_this.list1)
						_this.$forceUpdate();
						if (_this.current2 > _this.img_list1.length - 1) {
							_this.current2 = 0
							//_this.loading = false
						} else {
							_this.dis_certificate_api()
						}
					}
					
				})

				//});

			},
			dis_certificate_api1() {
				var _this = this
				// _this.loading = true
				this.saomiao_show = true
				let formData = new FormData()
				//var img = []
				console.log(this.current)
				//this.list.map((item, index) => {
				if (this.current == 0) {
					for (var i = 0; i <= this.img_list1[0].hege.length - 1; i++) {
						//img.push(this.img_list1[0].hege[i].imagePath)
						var file = this.img_list1[0].hege[i].imagePath
						formData.append('img', file)
					}
				} else if (this.current == 1) {
					if (this.img_list1[1].title == '身份证明') {
						console.log(123)
						for (var i = 0; i <= this.img_list1[1].hege.length - 1; i++) {
							//img.push(this.img_list1[0].hege[i].imagePath)
							var file = this.img_list1[1].hege[i].imagePath
							formData.append('img', file)
						}
					} else {
						console.log(this.img_list1[this.current].imagePath)
						var file = this.img_list1[this.current].imagePath
						formData.append('img', file)
					}

				} else {
					console.log(this.img_list1[this.current].imagePath)
					var file = this.img_list1[this.current].imagePath
					formData.append('img', file)
				}
				if (this.img_list1[this.current].title == '身份证明') {
					this.list[this.current].list = ["姓名", "住址", "公民身份号码", '有效期限']
				} else if (this.img_list1[this.current].title == '营业执照') {
					this.list[this.current].list = ["名称", "住所", "统一社会信用代码"]
				}
				if (this.img_list1.length >= 5) {
					this.list[3].list = ['气瓶数量']
				}
				formData.append('name_list', this.list[this.current].list)
				_this.$axios.post('dis_certificate_api', formData).then(function(res) {
					console.log(res)
					_this.saomiao_show = false
					if(res.data.data == '请重新识别'){
						// _this.$message({
						// 	message: '身份证已过期请重新上传',
						// 	type: 'error'
						// });
					}else{
						if (_this.current == 0) {
							_this.list1[_this.current].产品名称 = res.data.产品名称
							_this.list1[_this.current].充装介质 = res.data.充装介质
							_this.list1[_this.current].工作压力 = res.data.工作压力
							_this.list1[_this.current].气瓶容积 = res.data.气瓶容积
							_this.list1[_this.current].制作单位名称 = res.data.制作单位名称
							_this.list1[_this.current].制造日期 = res.data.制造日期
							_this.list1[_this.current].产品编号 = res.data.产品编号
						} else if (_this.current == 1) {
							if (_this.img_list1[_this.current].title == '身份证明') {
								var data = res.data.有效期限
								let currentDate = new Date();
								let year = currentDate.getFullYear();
								let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 注意月份从0开始，需要加1
								let day = currentDate.getDate();
								var data1 = year + "." + month + "." + day
								// if (new Date(data1) < new Date(data.substring(11))) {
								// 	console.log('没过期')
								// 	_this.isexpire = false
								// } else {
								// 	_this.dialogVisible = true
								// 	_this.isexpire = true
								// }
								_this.list1[_this.current].姓名 = res.data.姓名
								_this.list1[_this.current].住址 = res.data.住址
								_this.list1[_this.current].公民身份号码 = res.data.公民身份号码
							} else if (_this.img_list1[_this.current].title == '营业执照') {
								_this.list1[_this.current].名称 = res.data.名称
								_this.list1[_this.current].住所 = res.data.住所
								_this.list1[_this.current].统一社会信用代码 = res.data.统一社会信用代码
							}
						} else if (_this.current == 2) {
							_this.list1[_this.current].机动车登记编号 = res.data.机动车登记编号
							_this.list1[_this.current].车辆识别代号 = res.data.车辆识别代号
						} else if (_this.current == 3) {
							// _this.list1[_this.current].气瓶数量 = res.data.气瓶数量
							// _this.list1[_this.current].安装单位 = res.data.安装单位
							// _this.list1[_this.current].安全管理员 = res.data.安全管理员
							if (_this.img_list1.length >= 5) {
								_this.list1[_this.current].气瓶数量 = res.data.气瓶数量
							} else {
								_this.list1[_this.current].气瓶数量 = res.data.气瓶数量
								_this.list1[_this.current].安装单位 = res.data.安装单位
								_this.list1[_this.current].安全管理员 = res.data.安全管理员
							}
						} else if (_this.current == 4) {
							_this.list1[_this.current].安装单位 = res.data.安装单位
							_this.list1[_this.current].安全管理员 = res.data.安全管理员
						}
						console.log(_this.list1)
						_this.$forceUpdate();
					}
					
					
					// if (_this.current2 > _this.img_list1.length - 1) {
					// 	_this.current2 = 0
					// }else{
					// 	_this.dis_certificate_api()
					// }
				})

				//});

			},
			handleClose() {
				this.dialogVisible1 = false
				this.dialogVisible2 = false
				this.$refs.word.innerHTML = '';
			},
			open_camera(e) {
				if (e == 0) {
					this.camera = false
				} else {
					this.camera = true
				}
				this.dialogVisible1 = true
				setTimeout(() => {
					this.getCompetence()
				}, 500)
			},
			open_camera1(e) {
				if (e == 0) {
					this.camera = false
				} else {
					this.camera = true
				}
			},
			// 调用权限（打开摄像头功能）
			getCompetence() {
				var _this = this;
				_this.thisCancas = document.getElementById("canvasCamera");
				_this.thisContext = this.thisCancas.getContext("2d");
				_this.thisVideo = document.getElementById("videoCamera");
				_this.thisVideo.style.display = "block";
				// 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
				if (navigator.mediaDevices === undefined) {
					navigator.mediaDevices = {};
				}
				// 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
				// 使用getUserMedia，因为它会覆盖现有的属性。
				// 这里，如果缺少getUserMedia属性，就添加它。
				if (navigator.mediaDevices.getUserMedia === undefined) {
					navigator.mediaDevices.getUserMedia = function(constraints) {
						// 首先获取现存的getUserMedia(如果存在)
						var getUserMedia =
							navigator.webkitGetUserMedia ||
							navigator.mozGetUserMedia ||
							navigator.getUserMedia;
						// 有些浏览器不支持，会返回错误信息
						// 保持接口一致
						if (!getUserMedia) {
							//不存在则报错
							return Promise.reject(
								new Error("getUserMedia is not implemented in this browser")
							);
						}
						// 否则，使用Promise将调用包装到旧的navigator.getUserMedia
						return new Promise(function(resolve, reject) {
							getUserMedia.call(navigator, constraints, resolve, reject);
						});
					};
				}
				var constraints = {
					audio: false,
					video: {
						width: this.videoWidth,
						height: this.videoHeight,
						transform: "scaleX(-1)",
					},
				};
				navigator.mediaDevices
					.getUserMedia(constraints)
					.then(function(stream) {
						// 旧的浏览器可能没有srcObject
						if ("srcObject" in _this.thisVideo) {
							_this.thisVideo.srcObject = stream;
						} else {
							// 避免在新的浏览器中使用它，因为它正在被弃用。
							_this.thisVideo.src = window.URL.createObjectURL(stream);
						}
						_this.thisVideo.onloadedmetadata = function(e) {
							_this.thisVideo.play();
						};
					})
					.catch((err) => {
						console.log(err);
					});
			},
			//  绘制图片（拍照功能）
			setImage() {
				var _this = this;
				// canvas画图
				_this.thisContext.drawImage(
					_this.thisVideo,
					0,
					0,
					_this.videoWidth,
					_this.videoHeight
				);
				// 获取图片base64链接
				var image = this.thisCancas.toDataURL("image/png");
				//console.log(image)
				var file = _this.dataURLtoFile(image, 143)
				if (this.camera) {
					this.img_list1[this.current].imagePath = file
					this.img_list1[this.current].imageUrl = URL.createObjectURL(file);
					this.img_list1[this.current].srcList[0] = URL.createObjectURL(file);
				} else {
					this.img_list1[this.current].hege[this.current1].imagePath = file
					this.img_list1[this.current].hege[this.current1].imageUrl = URL.createObjectURL(file);
					this.img_list1[this.current].hege[this.current1].srcList[0] = URL.createObjectURL(file);
				}
				this.dialogVisible1 = false
				this.dis_certificate_api1()
			},
			// base64转文件，此处没用到
			dataURLtoFile(dataurl, filename) {
				console.log(dataurl,'1111111111111111')
				console.log(filename)
				const arr = dataurl.split(',');
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);

				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}

				// 将Uint8Array转换为Blob对象
				const blob = new Blob([u8arr], {
					type: mime
				});

				// 创建File对象
				const file = new File([blob], filename, {
					type: mime
				});

				return file;
			},
			todetails(e) {
				this.current1 = e
				this.restoration()
			},
			httprequest() {},
			//获取图片路径
			beforeAvatarUpload(file) {
				console.log(file)
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				if (!isJPG && !isPNG) {
					this.$message.error('只能上传图片!');
				} else {
					if (this.camera) {
						this.img_list1[this.current].imagePath = file
						this.img_list1[this.current].imageUrl = URL.createObjectURL(file);
						this.img_list1[this.current].srcList[0] = URL.createObjectURL(file);
					} else {
						this.img_list1[this.current].hege[this.current1].imagePath = file
						this.img_list1[this.current].hege[this.current1].imageUrl = URL.createObjectURL(file);
						this.img_list1[this.current].hege[this.current1].srcList[0] = URL.createObjectURL(file);
					}
					this.dis_certificate_api1()
					// this.img_list1[this.current].imagePath = file
					// this.img_list1[this.current].imageUrl = URL.createObjectURL(file);
					// this.img_list1[this.current].srcList[0] = URL.createObjectURL(file);
				}
			},
			aaa() {
				console.log(document.body.clientWidth)
				console.log(document.body.clientHeight)
				this.screenHeight = document.body.clientHeight
			},
			select(e) {
				console.log(e)
				this.current = e
				//this.restoration()
				if (this.current == 0) {
					this.brightness_list = ['产品名称', '充装介质', '工作压力', '气瓶容积', '制作单位名称', '制造日期', '产品编号']
					// _this.list1[_this.current].产品名称 = res.data.产品名称
					// _this.list1[_this.current].充装介质 = res.data.充装介质
					// _this.list1[_this.current].工作压力 = res.data.工作压力
					// _this.list1[_this.current].气瓶容积 = res.data.气瓶容积
					// _this.list1[_this.current].制作单位名称 = res.data.制作单位名称
					// _this.list1[_this.current].制造日期 = res.data.制造日期
					// _this.list1[_this.current].产品编号 = res.data.产品编号
				}
				// else if (_this.current == 1) {
				// 	if (_this.img_list1[_this.current].title == '身份证明') {
				// 		var data = res.data.有效期限
				// 		let currentDate = new Date();
				// 		let year = currentDate.getFullYear();
				// 		let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 注意月份从0开始，需要加1
				// 		let day = currentDate.getDate();
				// 		var data1 = year + "." + month + "." + day
				// 		if (new Date(data1) < new Date(data.substring(11))) {
				// 			console.log('没过期')
				// 			_this.isexpire = false
				// 		} else {
				// 			_this.dialogVisible = true
				// 			_this.isexpire = true
				// 		}
				// 		_this.list1[_this.current].姓名 = res.data.姓名
				// 		_this.list1[_this.current].住址 = res.data.住址
				// 		_this.list1[_this.current].公民身份号码 = res.data.公民身份号码
				// 	} else if (_this.img_list1[_this.current].title == '营业执照') {
				// 		_this.list1[_this.current].名称 = res.data.名称
				// 		_this.list1[_this.current].住所 = res.data.住所
				// 		_this.list1[_this.current].统一社会信用代码 = res.data.统一社会信用代码
				// 	}
				// } else if (_this.current == 2) {
				// 	_this.list1[_this.current].机动车登记编号 = res.data.机动车登记编号
				// 	_this.list1[_this.current].车辆识别代号 = res.data.车辆识别代号
				// } else if (_this.current == 3) {
				// 	// _this.list1[_this.current].气瓶数量 = res.data.气瓶数量
				// 	// _this.list1[_this.current].安装单位 = res.data.安装单位
				// 	// _this.list1[_this.current].安全管理员 = res.data.安全管理员
				// 	if (_this.img_list1.length >= 5) {
				// 		_this.list1[_this.current].气瓶数量 = res.data.气瓶数量
				// 	} else {
				// 		_this.list1[_this.current].气瓶数量 = res.data.气瓶数量
				// 		_this.list1[_this.current].安装单位 = res.data.安装单位
				// 		_this.list1[_this.current].安全管理员 = res.data.安全管理员
				// 	}
				// } else if (_this.current == 4) {
				// 	_this.list1[_this.current].安装单位 = res.data.安装单位
				// 	_this.list1[_this.current].安全管理员 = res.data.安全管理员
				// }
			},
			//handerPictu(e) {
			// if (e.wheelDelta || e.detail) {
			// 	if (e.wheelDelta > 0 || e.detail < 0) { //当鼠标滚轮向上滚动时
			// 		if (this.current == 0) {
			// 			this.current = this.img_list1.length - 1
			// 		} else {
			// 			this.current = this.current - 1
			// 		}
			// 		//console.log("向上")
			// 	}
			// 	if (e.wheelDelta < 0 || e.detail > 0) { //当鼠标滚轮向下滚动时
			// 		//console.log("向下")
			// 		if (this.current == this.img_list1.length - 1) {
			// 			this.current = 0
			// 		} else {
			// 			this.current = this.current + 1
			// 		}
			// 	}
			// }
			//}
		},

	}
</script>
<style lang="less" scoped>
	.wrap {
		width: 19.98rem;
		height: calc(100vh - 1.45rem);
		margin-top: 0.26rem;
		margin-left: 0.37rem;
		//background-color: #fff;
		border-radius: 0.1rem;
		// overflow-y: auto;
		// scrollbar-width: none; /* 对于 Firefox 隐藏滚动条 */
		display: flex;
	}

	.wrap::-webkit-scrollbar {
		display: none;
		/* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
	}

	// .wrap {
	// 	//background-color: #000;
	// 	width: 100vw;
	// 	height: calc(100vh - 0.71rem);
	// 	background-image: url('../assets/image/index1.png');
	// 	background-size: 100% 100%;
	// 	background-position: center center;
	// 	background-repeat: no-repeat;
	// 	overflow-y: auto;
	// }

	.my_template {
		font-weight: bold;
		font-size: 0.24rem;
		color: #030A1A;
	}

	.select {
		margin-right: 0.82rem;
		font-weight: 400;
		font-size: 0.2rem;
		color: #7B8391;
	}

	.select1 {
		margin-right: 0.82rem;
		font-weight: bold;
		font-size: 0.2rem;
		color: #2C60F5;
	}

	.left {
		width: 11.22rem;
		height: 6.76rem;
		background: #EBEEF2;
		position: relative;
		overflow: hidden;
		margin-left: 0.37rem;
		margin-top: 0.12rem;
	}

	.right {
		width: 5.12rem;
		height: 6.96rem;
		background: #F2F6FA;
	}

	/deep/.el-upload-dragger {
		width: 1.59rem;
		height: 0.54rem;
		background: #F6990E;
		//margin-top: 0.26rem;
		border: none;
	}

	.cha1 {
		border: 0.02rem solid #2C60F5;
		overflow: hidden;
	}

	/deep/.el-input__inner {
		height: 0.61rem;
		border-radius: 0.1rem;
		font-size: 0.2rem;
	}

	/deep/.el-dialog__headerbtn .el-dialog__close {
		font-size: 0.35rem;
	}

	.preview-img-div {
		position: relative;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#productDrawing {
		width: 580px;
		height: 480px;
		border: 1px solid #edf1f5;
		overflow: hidden;

		.alert {
			height: 30px;
			font-size: 12px;
			line-height: 30px;
			border-radius: 2px;
			color: #9e7700;
			text-align: center;
			background: linear-gradient(90deg, #ffffff 0%, #fff7d3 50%, #fcfcfc 100%);
		}

		.productDrawing_Img {
			width: 580px;
			height: 450px;
			overflow: hidden;
			display: table-cell;
			vertical-align: middle;
			text-align: center;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	// .print-main {
	// 	position: fixed;
	// 	top: -10000px; //不在页面上显示

	// 	/deep/.docx-wrapper {
	// 		background: none;
	// 	}

	// 	/deep/.docx-wrapper>section.docx {
	// 		margin: 0;
	// 	}
	// }

	.dunpai {
		width: 11.22rem;
		height: 6.96rem;
		margin: 0 auto;
		margin-top: 209upx;
		position: relative;
	}

	.dunpai image {
		width: 100%;
		height: 100%;
	}

	.saomiao {
		width: 100%;
		height: 0.95rem;
		background: linear-gradient(180deg, rgba(44, 96, 245, 0) 0%, #2C60F5 100%);
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 10px;
		animation: move 1.5s ease-in-out infinite;
		-webkit-animation: move 1.5s ease-in-out infinite;
		opacity: 0.57;
	}

	@keyframes move {
		from {
			top: 0px
		}

		/*网格移动到显示区域的外面*/
		to {
			top: 6.96rem
		}
	}

	.wrap_left {
		width: 12.76rem;
		height: 10.1rem;
		background: #FFFFFF;
		border-radius: 0.1rem;
	}

	.wrap_right {
		width: 7.07rem;
		height: 10.1rem;
		background: #FFFFFF;
		border-radius: 0.1rem;
		margin-left: 0.15rem;
	}

	.table_left {
		width: 0.46rem;
		height: 2.71rem;
		background: #FFFFFF;
		border-right: 0.01rem solid #787B81;
		border-bottom: 0.01rem solid #787B81;
		font-size: 0.15rem;
		display: flex;
		align-items: center;
		text-align: center;
		line-height: 0.25rem;
	}

	.brightness {
		background: rgba(44, 96, 245, 0.14);
	}

	/deep/.el-input__inner {
		height: 0.26rem;
		background-color: transparent;
		border-radius: 0;
		color: #000;
		font-size: 0.15rem;
		border: none;
	}
	/deep/.el-input__icon{
		line-height: 0.29rem;
		width: 0.01rem;
		height: 0.01rem;
		display: none;
	}
	/deep/.el-input--prefix .el-input__inner{
		padding: 0;
	}
	/deep/.el-input--suffix .el-input__inner{
		padding: 0;
	}
</style>