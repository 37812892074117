<template>
	<div class="wrap">
		<!-- <div class="dunpai">
			<img src="../assets/image/1.png" mode=""></img>
			<div class="saomiao">

			</div>
			<text class="dunpai-baifenbi">35<text style="font-size: 30upx;">%</text></text>
		</div> -->

		<el-dialog title="" :visible.sync="dialogVisible" width="7.07rem" :before-close="handleClose">
			<div style="text-align: center;position: relative;">
				<img style="width: 3.74rem;height: 2.63rem;" src="../assets/image/1.png" alt="" srcset="" />
				<div
					style="font-weight: 400;font-size: 0.22rem;color: #7B8391;position: absolute;bottom: 0.35rem;left: 50%;margin-left: -1.4rem;">
					模块正在开发中，敬请期待...</div>
			</div>
			<div @click="dialogVisible = false"
				style="width: 3.07rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;margin: 0 auto;margin-top: 0.45rem;margin-bottom: 0.3rem;">
				关闭
			</div>
		</el-dialog>
		<!-- <div></div> -->
		<div style="width: 19.98rem;margin: 0 auto;min-width: 19.98rem;">
			<!-- <div style="display: flex;justify-content: space-between;">
				<div>
					<div style="display: flex;align-items: center;margin-top: 0.55rem;">
						<img style="width: 0.46rem;height: 0.46rem;" src="../assets/image/index2.png" alt=""
							srcset="" />
						<div class="my_template">我的模板</div>
					</div>
					<div class="my_template_text">常用识别模版以及自定义模版</div>
				</div>
				<div @click="dialogVisible = true"
					style="width: 3.71rem;height: 0.79rem;background: #FFFFFF;border-radius: 0.1rem;border: 0.01rem solid #D8D8D8;margin-top: 0.55rem;display: flex;justify-content: space-between;align-items: center;">
					<div style="display: flex;align-items: center;margin-left: 0.21rem;">
						<img src="../assets/image/index20.png" alt="" />
						<div style="font-weight: 400;font-size: 0.2rem;color: #030A1A;margin-left: 0.13rem;">自定义模版</div>
					</div>
					<img style="margin-right: 0.21rem;" src="../assets/image/index21.png" alt="" />
				</div>
			</div>

			<div style="position: relative;">
				<img style="margin-top: 0.37rem;" src="../assets/image/index19.png" alt="" srcset="" />
				<div style="position: absolute;top: 0.5rem;left: 0.3rem;color: #FFFFFF;">
					<div style="font-weight: bold;font-size: 0.29rem;margin-top: 0.35rem;">暂无模版</div>
					<div style="font-weight: 400;font-size: 0.2rem;margin-top: 0.15rem;width: 3.14rem;">
						您可以在通用识别里添加常用的模版到此处或者自定义模版。</div>
				</div>
			</div> -->
			<div style="display: flex;align-items: center;margin-top: 0.48rem;">
				<img style="width: 0.27rem;height: 0.27rem;" src="../assets/image/index2.png" alt="" srcset="" />
				<div class="my_template">通用识别</div>
			</div>

			<div class="my_template_text">为您提供卡证件、交通、财务、医疗等场景下的常用文字识别</div>
			<div class="list" style="">
				<div v-for="(item,index) in list" class="box" @mouseenter="enter(index)" @mouseleave="leave(index)">
					<div>
						<img class="img" :src="item.image" alt="" srcset="" />
						<div style="margin: 0 0.33rem;">
							<div
								style="font-weight: bold;font-size: 0.29rem;color: #0F131A;margin: 0.38rem 0 0.18rem 0;">
								{{item.title}}
							</div>
							<div style="font-weight: 400;font-size: 0.2rem;color: #7B8391;">{{item.text}}</div>
						</div>
						<div @click="open(item.title)"
							style="width: 3.07rem;height: 0.54rem;background: #2C60F5;font-weight: 400;font-size: 0.2rem;color: #FFFFFF;text-align: center;line-height: 0.54rem;margin: 0 auto;margin-top: 0.76rem;">
							立即使用
						</div>
					</div>
					<div v-if="animate == true && current == index"
						:style="{ backgroundImage: 'url(' + item.image1 + ')' }"
						:class="{ 'slide-in-from-bottom': animate }">
						<div style="margin: 0 0.33rem;">
							<div style="font-weight: bold;font-size: 0.29rem;color: #fff;margin: 0.38rem 0 0.18rem 0;">
								{{item.title}}
							</div>
							<div style="font-weight: 400;font-size: 0.2rem;color: #fff;">{{item.text}}</div>
						</div>
						<div @click="open(item.title)"
							style="width: 3.07rem;height: 0.54rem;background: #fff;font-weight: 400;font-size: 0.2rem;color: #2C60F5;text-align: center;line-height: 0.54rem;margin: 0 auto;margin-top: 2.85rem;">
							立即使用
						</div>
					</div>
					<div v-if="animate == false && current == index" :class="{ 'slide-in-from-bottom1': animate1 }">

					</div>
				</div>
			</div>
		</div>
		<div style="height: 0.57rem;"></div>
	</div>
</template>

<script>
	//万物识别
	import '../assets/icon/iconfont.css'
	export default {
		data() {
			return {
				show: false,
				dialogVisible: false,
				animate: false,
				animate1: false,
				current: "",
				list: [{
					image: require("@/assets/image/index3.png"),
					title: '卡证文字识别',
					text: '身份证、银行卡、户口本、驾驶证、护照等识别',
					image1: require("@/assets/image/index11.png")
				}, {
					image: require("@/assets/image/index4.png"),
					title: '特种设备使用登记',
					text: '合格证、检验证书、安装合格证、身份证、行车本等识别',
					image1: require("@/assets/image/index12.png")
				}, {
					image: require("@/assets/image/index5.png"),
					title: '交通场景类',
					text: '行驶证、驾驶证、车牌、车辆合格证等识别',
					image1: require("@/assets/image/index13.png")
				}, {
					image: require("@/assets/image/index6.png"),
					title: '财务票据类',
					text: '财税报销、税务核算等场景票据单据识别',
					image1: require("@/assets/image/index14.png")
				}, {
					image: require("@/assets/image/index7.png"),
					title: '医疗票据类',
					text: '医疗住院收费票据、医疗保险单等识别',
					image1: require("@/assets/image/index15.png")
				}, {
					image: require("@/assets/image/index8.png"),
					title: '商品销售类',
					text: '商品购销存统计表、销售数据明细报表等识别',
					image1: require("@/assets/image/index16.png")
				}, {
					image: require("@/assets/image/index9.png"),
					title: '公司管理类',
					text: '员工入职个人信息表、工资条、办公用品采购表等识别',
					image1: require("@/assets/image/index17.png")
				}, {
					image: require("@/assets/image/index10.png"),
					title: '学校统计类',
					text: '课程表、学生个人信息收集表、教师考勤表等识别',
					image1: require("@/assets/image/index18.png")
				}]
			}
		},
		created() {

		},
		watch: {

		},
		computed: {

		},
		mounted() {
			// 当组件挂载时，设置初始REM大小
			this.setRem();
			// 监听窗口大小变化，并重新设置REM大小
			window.addEventListener('resize', this.setRem);
		},
		methods: {
			handleClose(){
				this.dialogVisible = false
			},
			setRem() {
				// 假设我们想根据设计稿的宽度调整REM大小
				// 设计稿宽度为750px
				const designWidth = 1920;
				// 获取当前窗口的宽度
				const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
				console.log(document.documentElement.clientWidth)
				console.log(document.body.clientWidth)
				console.log(clientWidth)
				// 计算REM大小
				const rem = 1920 / designWidth * 80;
				// 设置HTML的字体大小
				document.documentElement.style.fontSize = `${rem}px`;
			},
			open(e) {
				if (e == "特种设备使用登记") {
					this.$router.push('/identify')
				} else {
					this.dialogVisible = true
				}
			},
			enter(e) {
				this.animate = true
				this.current = e
				this.animate1 = false
			},
			leave(e) {
				this.animate = false
				this.animate1 = true
				this.current = e
			}
		},

	}
</script>

<style lang="less" scoped>
	.wrap {
		width: 19.98rem;
		height: calc(100vh - 1rem);
		margin-left: 0.37rem;
		overflow-y: auto;
		scrollbar-width: none; /* 对于 Firefox 隐藏滚动条 */
		background-color: #F2F6FA;
	}
	.wrap::-webkit-scrollbar {
	  display: none; /* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
	}

	.box {
		width: 3.73rem;
		height: 5.37rem;
		background-color: #fff;
		position: relative;
		margin-top: 0.32rem;
	}

	@keyframes slideInFromBottom {
		0% {
			height: 0;
			bottom: 0;
		}

		100% {
			height: 100%;
		}
	}

	@keyframes slideInFromBottom1 {
		0% {
			height: 100%;
		}

		100% {
			height: 0;
			bottom: 0;
		}
	}

	.slide-in-from-bottom {
		position: absolute;
		bottom: 0;
		width: 100%;
		//background-color: #f0f0f0;
		/* 初始颜色 */
		animation: slideInFromBottom 0.5s ease-in-out forwards;
		//background-image: url('../assets/image/index11.png');
		background-size: 100% 100%;
		background-position: center center;
		background-repeat: no-repeat;
		overflow: hidden;
	}

	.slide-in-from-bottom1 {
		position: absolute;
		bottom: 0;
		width: 100%;
		/* 初始颜色 */
		animation: slideInFromBottom1 0.5s ease-in-out forwards;
		//background-image: url('../assets/image/index11.png');
		background-size: 100% 100%;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.my_template {
		font-weight: bold;
		font-size: 0.24rem;
		color: #030A1A;
	}

	.my_template_text {
		font-weight: 400;
		font-size: 0.2rem;
		color: #7B8391;
		margin-top: 0.09rem;
	}

	.list {
		display: grid;
		justify-content: space-between;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		/*设置等比列*/
	}

	.img {
		width: 3.73rem;
		height: 2.05rem;
	}

	// .box:after {
	// 	// 使用伪类元素占据单位，不影响页面
	// 	content: "";
	// 	width: 3.73rem;
	// 	height: 5.37rem;
	// }


	.dunpai {
		width: 210upx;
		height: 210upx;
		margin: 0 auto;
		margin-top: 209upx;
		position: relative;
	}

	.dunpai image {
		width: 100%;
		height: 100%;
	}

	// .saomiao {
	// 	width: 80%;
	// 	height: 48px;
	// 	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
	// 	position: absolute;
	// 	left: 50%;
	// 	transform: translateX(-50%);
	// 	top: 10px;
	// 	animation: move 1s ease-in-out infinite;
	// 	-webkit-animation: move 1s ease-in-out infinite;
	// }

	// @keyframes move {
	// 	from {
	// 		top: 0px
	// 	}

	// 	/*网格移动到显示区域的外面*/
	// 	to {
	// 		top: 100px
	// 	}
	// }

	
</style>