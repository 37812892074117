<template>
	<div class="wrap">
		<div style="height: 0.3rem;"></div>
		<div style="display: flex;flex-direction: column;align-items: center;" v-for="(item,index) in list">
			<div style="">
				<div style="margin: 0.15rem 0 0.15rem 0.15rem;color: #7B8391;font-size: 0.15rem;"
					v-if="item.title != ''">{{item.title}}</div>
				<div @click="select(index)" :class="current == index ?'list1':'list'">
					<img style="margin-left: 0.23rem;" :src="item.img" alt="" srcset="" />
					<div style="margin-left: 0.12rem;font-size: 0.17rem;color: #030A1A;">{{item.name}}</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			state: Array,
		},
		data() {
			return {
				current: 0,
				list: [{
					name: "为你推荐",
					title: "",
					img: require('@/assets/image/list1.png')
				}, {
					name: "通用识别",
					title: "",
					img: require('@/assets/image/list2.png')
				}, {
					name: "我的收藏",
					title: "",
					img: require('@/assets/image/list3.png')
				}, {
					name: "特种设备使用登记",
					title: "私有定制",
					img: require('@/assets/image/list4.png')
				}, {
					name: "模版创建",
					title: "自定义模版",
					img: require('@/assets/image/list5.png')
				}, {
					name: "我的模版",
					title: "",
					img: require('@/assets/image/list6.png')
				}, {
					name: "历史识别",
					title: "历史记录",
					img: require('@/assets/image/list7.png')
				}]
			}
		},
		mounted() {
			console.log(this.$route)
			if (this.$route.path == '/index') {
				this.current = 1
			} else if (this.$route.path == '/identify') {
				this.current = 3
			} else if (this.$route.path == '/record') {
				this.current = 6
			} else if (this.$route.path == '/recommend') {
				this.current = 0
			} else if (this.$route.path == '/collect') {
				this.current = 2
			}
		},
		computed: {

		},
		methods: {
			select(e) {
				this.current = e
				if (e == 1) {
					this.$router.push('/index')
				} else if (e == 3) {
					this.$router.push('/identify')
				} else if (e == 6) {
					this.$router.push('/record')
				} else if (e == 0) {
					this.$router.push('/recommend')
				} else if (e == 2) {
					this.$router.push('/collect')
				}
			}
		},
	}
</script>

<style scoped lang="less">
	.wrap {
		width: 2.68rem;
		height: calc(100vh - 0.72rem);
		background: #FFFFFF;
	}

	.list {
		width: 2.29rem;
		height: 0.49rem;
		//background: rgba(44,96,245,0.1);
		border-radius: 0.1rem;
		display: flex;
		align-items: center;
	}

	.list1 {
		width: 2.29rem;
		height: 0.49rem;
		background: rgba(44, 96, 245, 0.1);
		border-radius: 0.1rem;
		display: flex;
		align-items: center;
	}
</style>