<template>
	<div>
		<div class="white">
			<el-container>
				<el-header>
					<Card></Card>
				</el-header>
				<el-container>
					<el-aside width="auto">
						<List></List>
					</el-aside>
					<el-main>
						<router-view></router-view>
					</el-main>
				</el-container>
			</el-container>
		</div>
	</div>
</template>

<script>
	import Card from '../components/card.vue'
	import List from '../components/list.vue'
	export default {
		components: {
			Card,
			List
		},
		data() {
			return {

			}
		},
		created() {

		},
		computed: {

		},
		mounted() {
			// 当组件挂载时，设置初始REM大小
			this.setRem();
			// 监听窗口大小变化，并重新设置REM大小
			window.addEventListener('resize', this.setRem);
		},
		methods: {
			setRem() {
				// 假设我们想根据设计稿的宽度调整REM大小
				// 设计稿宽度为750px
				const designWidth = 1920;
				// 获取当前窗口的宽度
				const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
				console.log(document.documentElement.clientWidth)
				console.log(document.body.clientWidth)
				console.log(clientWidth)
				// 计算REM大小
				const rem = 1920 / designWidth * 80;
				// 设置HTML的字体大小
				document.documentElement.style.fontSize = `${rem}px`;
			},
		},
	}
</script>

<style lang="less" scoped>
	body {
		//border: 1px red solid;
		overflow: scroll;
	}

	/deep/.el-header {
		padding: 0;
		height: 0.72rem !important;
		//height: 7vh !important;
	}

	.el-main {
		padding: 0;
		background-color: #F2F6FA;
	}
</style>