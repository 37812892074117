<template>
	<div class="wrap">
		<div class="left">
			<img style="width: 9rem;height: 7.68rem;margin-top: 2.74rem;margin-left: 2rem;"
				src="../assets/image/login2.png" alt="" srcset="" />
		</div>
		<div class="right">
			<div style="margin-left: 2.32rem;">
				<div class="title">
					欢迎来到智录星
				</div>
				<div style="width: 4.88rem;margin-top: 0.61rem;">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
						<div class="login_name">账号</div>
						<el-form-item label-width="0" prop="name">
							<el-input style="color: red;" placeholder="请输入账号" v-model="ruleForm.name"></el-input>
						</el-form-item>
						<div class="login_name" style="margin-top: 0.4rem;">密码</div>
						<el-form-item label-width="0" prop="password">
							<el-input show-password placeholder="请输入密码" type="password"
								v-model="ruleForm.password"></el-input>
						</el-form-item>
						<el-form-item>
							<div class="login" @click="login('ruleForm')">
								登录
							</div>
						</el-form-item>
						<el-form-item prop="checked">
							<el-checkbox style="margin-left: 0.26rem;" v-model="ruleForm.checked">
								<div style="margin-top: 0.27rem;font-size: 0.17rem;">我已阅读并同意 <span
										style="color: #2C60F5;">服务协议</span> 和 <span style="color: #2C60F5;">隐私协议</span>
								</div>
							</el-checkbox>
						</el-form-item>
					</el-form>
				</div>

			</div>
		</div>
		<!-- <img src="../assets/image/login1.png" alt="" srcset="" /> -->
		<!-- <div class="admin-wrap1">全民健身竞赛管理系统</div>
		<div class="admin-wrap">
			<div class="logo">用户登录</div>
			<div class="admin">
				<el-input placeholder="账号" prefix-icon="el-icon-user" v-model="name" @input="admin"></el-input>
			</div>
			<div class="admin">
				<el-input prefix-icon="el-icon-search" placeholder="密码" v-model="password" @input="passwords"
					show-password></el-input>
			</div>
			<div class="button">
				<el-button type="primary" style="width: 350px;" @click="login">登录</el-button>
			</div>
			<div class="bottom">
				<el-checkbox v-model="checked">记住密码</el-checkbox>
				<div style="color: #2f9688;">忘记密码</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	import {
		loginApi
	} from "../api/index.js" //引入
	export default {
		data() {
			return {
				name1: "admin",
				password1: '123456',
				ruleForm: {
					name: '',
					password: '',
					checked: ""
				},
				rules: {
					name: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					checked: [{
						//type: 'array',
						required: true,
						message: '请选中服务',
						trigger: 'change'
					}],
				}
			}
		},
		created() {

		},
		mounted() {

		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			admin() {
				var admin = this.name
				//console.log(admin)
			},
			passwords() {
				var password = this.password
				//console.log(password)
			},
			login(formName) {
				var _this = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.ruleForm.checked) {
							if (this.ruleForm.name == this.name1 && this.ruleForm.password == this.password1) {
								_this.$message({
									message: '登录成功',
									type: 'success'
								})
								localStorage.setItem('token', "lksajfklasjfklas")
								localStorage.setItem('name', "admin")
								setTimeout(() => {
									//需要定时执行的代码
									_this.$router.push('/index')
								}, 1500)
							}
						} else {
							_this.$message({
								message: '请选中服务',
								type: 'error'
							});
						}

					} else {
						return false;
					}
				});
				// var _this = this
				// if (this.admin == '') {
				// 	console.log("请输入用账号")
				// } else if (this.password == '') {
				// 	console.log("请输入密码")
				// }else {
				// 	var params = {
				// 		user_name: this.name,
				// 		user_password: this.password
				// 	}
				// 	loginApi(params).then(res => {
				// 		console.log(res)
				// 		if (res.data.code == '200') {
				// 			_this.$message({
				// 				message: '登录成功',
				// 				type: 'success'
				// 			})
				// 			var menus = JSON.stringify(res.data.data[0])
				// 			var menus1 = JSON.stringify(res.data.menu)
				// 			var bumen = JSON.stringify(res.data.bumen)
				// 			localStorage.setItem('token', res.data.data[0].token)
				// 			localStorage.setItem('menus', menus)
				// 			localStorage.setItem('menus1', menus1)
				// 			localStorage.setItem('bumen', bumen)
				// 			setTimeout(() => {
				// 				//需要定时执行的代码
				// 				_this.$router.push('/all')
				// 			}, 1500)
				// 		} else {
				// 			_this.$message({
				// 				message: res.data.wenzi,
				// 				type: 'error'
				// 			})
				// 		}
				// 	})

				// }

			},
		},
	}
</script>

<style scoped>
	* {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.wrap {
		width: 100vw;
		height: 100vh;
		background-image: url('../assets/image/login1.png');
		background-size: 100% 100%;
		background-position: center center;
		background-repeat: no-repeat;
		display: flex;
	}

	.left {
		width: 13.63rem;
	}

	.right {
		width: 10.37rem;
		height: 100vh;
		background: #FFFFFF;
	}

	.title {
		font-weight: bold;
		font-size: 0.44rem;
		color: #0F131A;
		margin-top: 3.17rem;
	}

	.login_name {
		font-weight: 400;
		font-size: 0.2rem;
		color: #7B8391;
		margin-bottom: 0.11rem;
	}

	.login {
		width: 4.88rem;
		height: 0.68rem;
		background: #2C60F5;
		border-radius: 0.34rem;
		text-align: center;
		line-height: 0.68rem;
		font-weight: 400;
		font-size: 0.2rem;
		color: #FFFFFF;
		margin-top: 0.89rem;
	}

	/deep/.el-input__inner {
		height: 0.61rem;
		border-radius: 0.1rem;
		font-size: 0.2rem;
		color: #0F131A;
	}

	/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #7B8391;
	}

	/deep/.el-dropdown-menu__item .el-menu-item {
		font-size: 0.3rem;
	}
</style>