import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import Identify from '../views/Identify.vue'
import Identify_details from '../views/Identify_details.vue'
import Record from '../views/Record.vue'
import Recommend from '../views/Recommend.vue'
import Collect from '../views/Collect.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
	if (resolve || reject) return originalPush.call(this, location, resolve, reject)
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
//1.创建路由组件
//2.将路由和组件进行映射
const routes = [{
	path: '/login',
	name: 'login',
	component: Login
}, {
	path: '/',
	component: Main,
	redirect: '/index', //重定向
	children: [
		{path: '/index',name: 'index',component: Index}, //首页
		{path: '/identify',name: 'identify',component: Identify}, //上传图片
		{path: '/identify_details',name: 'identify_details',component: Identify_details}, //识别
		{path: '/record',name: 'record',component: Record}, //历史识别
		{path: '/recommend',name: 'Recommend',component: Recommend}, //为你推荐
		{path: '/collect',name: 'Collect',component: Collect}, //我的收藏
	]
}]
const router = new VueRouter({
	routes // (缩写) 相当于 routes: routes
})

export default router