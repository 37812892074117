import axios from "axios" 
import Vue from 'vue'
import {Message} from 'element-ui'
let vue=new Vue()
axios.defaults.baseURL="https://zlx.akaikeji.com"
//axios.defaults.baseURL="http://192.168.0.120:5001"
axios.defaults.timeout=5000000000
let instance = axios.create()

export{
	instance,
}
