<template>
	<div class="wrap">
		<el-dialog title="" :visible.sync="dialogVisible" width="7.07rem" :before-close="handleClose">
			<div style="text-align: center;position: relative;">
				<img style="width: 3.74rem;height: 2.63rem;" src="../assets/image/identify2.png" alt="" srcset="" />
				<div
					style="font-weight: 400;font-size: 0.22rem;color: #7B8391;position: absolute;bottom: 0rem;left: 50%;margin-left: -2.1rem;">
					*未完成所有项图片的上传，请全部上传后再识别</div>
			</div>
			<div @click="dialogVisible = false"
				style="width: 3.07rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;margin: 0 auto;margin-top: 0.45rem;margin-bottom: 0.3rem;">
				返回
			</div>
		</el-dialog>
		<el-dialog title="" :visible.sync="dialogVisible1" width="15.44rem" :before-close="handleClose">
			<div class="camera_outer"
				style="width: 14.44rem;display: flex;justify-content: center;flex-direction: column;align-items: center;margin: 0 auto;height: 8.9rem;">
				<video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
				<canvas style="display: none" id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
				<div v-if="!camera">
					<div @click="setImage()" v-if="!camera_show"
						style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;">
						点击拍照</div>
					<div style="display: flex;" v-if="camera_show">
						<div @click="setImage()"
							style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;margin-right: 0.3rem;">
							重新拍照</div>
						<div @click="setImage2()"
							style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;">
							继续拍照</div>
					</div>
				</div>

				<div v-if="camera" @click="setImage1()"
					style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-top: 0.4rem;font-size: 0.2rem;">
					点击拍照</div>
			</div>
		</el-dialog>
		<div style="width: 18.7rem;margin: 0 auto;min-width: 18.7rem;">
			<div style="display: flex;justify-content: space-between;">
				<div>
					<div style="display: flex;align-items: center;margin-top: 0.2rem;">
						<img style="width: 0.27rem;height: 0.27rem;margin-right: 0.12rem;"
							src="../assets/image/index2.png" alt="" srcset="" />
						<div class="my_template">特种设备使用登记</div>
					</div>
					<div style="font-weight: 400;font-size: 0.2rem;color: #7B8391;margin-top: 0.06rem;">
						新设备首次启用，适用于车用气瓶使用登记</div>
				</div>
				<div style="display: flex;margin-top: 0.27rem;">
					<div
						style="width: 1.33rem;height: 0.54rem;background: #FFFFFF;border: 0.01rem solid #2C60F5;margin-right: 0.16rem;text-align: center;line-height: 0.54rem;color: #2C60F5;font-size: 0.2rem;">
						批量上传</div>
					<div @click="identify"
						style="width: 3.07rem;height: 0.54rem;background: #2C60F5;text-align: center;line-height: 0.54rem;color: #fff;font-size: 0.2rem;">
						开始识别</div>
				</div>
			</div>





			<div style="font-weight: bold;font-size: 0.22rem;color: #0F131A;margin-top: 0.66rem;"><span
					style="color: #DF2525;">*</span>特种设备产品合格证（多张）</div>
			<div style="display: grid;justify-content: space-between;grid-template-columns: 1fr 1fr 1fr 1fr;">
				<div v-for="(item,index) in img_list1[0].hege" @click="getindex(index)">
					<div :class="item.imageUrl?'avatar-uploader1':'avatar-uploader'">
						<div @click.stop="image_close(index)" v-if="item.imageUrl"
							style="position: absolute;bottom: 0.09rem;right: 0.07rem;z-index: 999;width: 0.79rem;height: 0.41rem;background: #DF2525;text-align: center;line-height: 0.41rem;color: #fff;font-size: 0.2rem;">
							删除
						</div>
						<el-image v-if="item.imageUrl" style="width:auto;height: 2.31rem;" :src="item.imageUrl"
							:preview-src-list="srcList" :initial-index='index'>
						</el-image>
						<div v-else @click="open(index)">
							<img style="margin-top: 0.5rem;width: 0.36rem;height: 0.36rem;"
								src="../assets/image/identify1.png" alt="" />
							<div style="font-weight: 400;font-size: 0.19rem;color: #7B8391;margin-top: 0.18rem;">
								请拍照或上传图片进行识别</div>

							<div style="display: flex;justify-content: center;margin-top: 0.18rem;">
								<div@click="open_camera(item,0)"
									style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-right: 0.08rem;font-size: 0.2rem;">
									点击拍照
							</div>
							<div
								style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
								<div class="file-upload-wrapper">
									<input type="file" multiple ref="fileupload" id="file-upload"
										@change="handleFileChange" style="display: none;">
									<button @click="triggerFileUpload">上传图片</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="display: grid;justify-content: space-between;grid-template-columns: 1fr 1fr 1fr 1fr;">
			<div v-for="(item,index) in img_list1" style="" v-if="index != 0">
				<div v-if="item.title == '身份证明' || item.title == '营业执照'"
					style="display: flex;margin-top: 0.43rem;justify-content: space-between;width: 3.73rem;align-items: center;">
					<div style="font-weight: bold;font-size: 0.22rem;color: #0F131A;"><span style="color: #DF2525;"
							v-if="item.title != '监督检验证书'">*</span>{{item.title}}</div>
					<div style="display: flex;">
						<div @click="select(0,index)" :class="current == 0?'select1':'select'">身份证明</div>
						<div @click="select(1,index)" :class="current == 1?'select1':'select'">营业执照</div>
					</div>
				</div>
				<div v-if="item.title != '身份证明' && item.title != '营业执照'"
					style="font-weight: bold;font-size: 0.22rem;color: #0F131A;margin-top: 0.43rem;height: 0.44rem;line-height: 0.44rem;">
					<span style="color: #DF2525;"
						v-if="item.title != '监督检验证书' && item.title != ''">*</span>{{item.title}}
				</div>

				<div>
					<div @click="getindex(index)">
						<div :class="item.imageUrl?'avatar-uploader1':'avatar-uploader'">
							<!-- <div @click="updata1(index)" v-if="item.imageUrl"
								style="width: 0.79rem;height: 0.41rem;background: #2C60F5;position: absolute;bottom: 0.09rem;right: 0.93rem;z-index: 999;text-align: center;line-height: 0.41rem;color: #fff;font-size: 0.2rem;">
								更换
							</div> -->
							<div @click.stop="image_close1(index)" v-if="item.imageUrl"
								style="position: absolute;bottom: 0.09rem;right: 0.07rem;z-index: 999;width: 0.79rem;height: 0.41rem;background: #DF2525;text-align: center;line-height: 0.41rem;color: #fff;font-size: 0.2rem;">
								删除
							</div>
							<el-image v-if="item.imageUrl" style="width: 3.73rem;height: 2.31rem;" :src="item.imageUrl"
								:preview-src-list="[item.imageUrl]">
							</el-image>
							<div v-else @click="open1(index)">
								<img style="margin-top: 0.5rem;width: 0.36rem;height: 0.36rem;"
									src="../assets/image/identify1.png" alt="" />
								<!-- <div
										style="margin: 0.22rem 0.3rem 0 0.3rem;font-weight: 400;font-size: 0.2rem;color: #0F131A;">
										将多个图片拖拽到此
									</div> -->
								<div style="font-weight: 400;font-size: 0.19rem;color: #7B8391;margin-top: 0.18rem;">
									请拍照或上传图片进行识别</div>
								<div style="display: flex;justify-content: center;margin-top: 0.18rem;">
									<div @click="open_camera(item,1)"
										style="width: 1.59rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin-right: 0.08rem;font-size: 0.2rem;">
										点击拍照</div>
									<div
										style="width: 1.59rem;height: 0.54rem;background: #F6990E;color: #fff;text-align: center;line-height: 0.54rem;font-size: 0.2rem;">
										<el-upload drag accept="image/jpeg, image/png" :http-request='httprequest'
											class="avatar-uploader3" action="#" :show-file-list="false"
											:before-upload="beforeAvatarUpload1">
											<div>上传图片</div>
										</el-upload>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div style="margin-top: 0.55rem;">
		</div>
		<!-- <div @click="identify"
			style="width: 3.07rem;height: 0.54rem;background: #2C60F5;color: #fff;text-align: center;line-height: 0.54rem;margin: 0 auto;font-size: 0.2rem;margin-top: 0.55rem;">
			开始识别
		</div> -->



	</div>

	</div>
</template>

<script>
	//万物识别
	import '../assets/icon/iconfont.css'
	export default {
		data() {
			return {
				idCard: [{
					title: '身份证明',
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}, {
					title: '',
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}],
				businesslicense: {
					title: '营业执照',
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				},
				selectedFiles: [],
				base64String: null,
				camera_show: false,
				show: false,
				videoWidth: 960,
				videoHeight: 600,
				imgSrc: "",
				thisCancas: null,
				thisContext: null,
				thisVideo: null,
				openVideo: false,
				dialogVisible1: false,
				camera: false,
				show1: true,
				time1: '',


				current: 0,
				imageUrl: '',
				imagePath: '',
				srcList: [],
				srcList1: [],
				value: false,
				index: 0,
				dialogVisible: false,
				img_list: [{
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}],
				img_list1: [{
					title: '特种设备产品合格证（多张）',
					hege: [{
						imageUrl: '',
						imagePath: '',
						value: false,
						srcList: [],
					}]
				}, {
					title: '身份证明',
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}, {
					title: '',
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}, {
					title: '机动车登记证书',
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}, {
					title: '安装合格证',
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}, {
					title: '监督检验证书',
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}]
			}
		},
		created() {

		},
		watch: {

		},
		mounted() {
			//this.getCompetence(); //进入页面就调用摄像头
			this.time()

		},
		computed: {

		},
		methods: {
			triggerFileUpload() {
				document.getElementById('file-upload').click()
				//this.$refs.fileupload.click();
			},
			createArrayWithLength(length) {
				// 使用fill和Array.from创建特定长度的数组
				return Array.from({
					length: length
				}).fill(null);
			},
			handleFileChange(event) {
				console.log(event.target.files);
				// 处理文件
				var file = event.target.files
				var array = this.createArrayWithLength(file.length)
				array.map((item, index) => {
					this.getBase64(file[index]).then(res => {
						console.log(res)
						//this.img_list1[this.index].hege[this.index].imagePath = res
						console.log(this.img_list1[0].hege.length - 1)
						this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imagePath = res
						console.log(this.img_list1[0].hege.length - 1)
						console.log(this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imagePath)
						this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imageUrl = URL.createObjectURL(file[
							index]);
						this.img_list1[0].hege[this.img_list1[0].hege.length - 1].srcList[0] = URL.createObjectURL(
							file[index]);
						this.srcList.push(URL.createObjectURL(file[index]))
						if (this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imageUrl != '') {
							this.img_list1[0].hege[this.img_list1[0].hege.length - 1].value = true
						}
						//setTimeout(() => {
						var list = {
							imageUrl: '',
							imagePath: '',
							value: false,
							srcList: [],
						}
						this.img_list1[0].hege.push(list)
						//}, 500)
					})
					

				})

				// for (var i = 0; i <= file.length - 1; i++) {

				// 	this.getBase64(file[i]).then(res => {
				// 		console.log(res)
				// 		//this.img_list1[this.index].hege[this.index].imagePath = res
				// 		console.log(this.img_list1[0].hege.length - 1)
				// 		this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imagePath = res
				// 	})
				// 	console.log(this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imagePath)
				// 	this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imageUrl = URL.createObjectURL(file[i]);
				// 	this.img_list1[0].hege[this.img_list1[0].hege.length - 1].srcList[0] = URL.createObjectURL(file[i]);
				// 	this.srcList.push(URL.createObjectURL(file[i]))
				// 	if (this.img_list1[0].hege[this.img_list1[0].hege.length - 1].imageUrl != '') {
				// 		this.img_list1[0].hege[this.img_list1[0].hege.length - 1].value = true
				// 	}
				// 	setTimeout(() => {
				// 		var list = {
				// 			imageUrl: '',
				// 			imagePath: '',
				// 			value: false,
				// 			srcList: [],
				// 		}
				// 		this.img_list1[0].hege.push(list)
				// 	}, 500)

				// 	// if (this.index == this.img_list1[0].hege.length - 1 && this.img_list1[0].hege[this.index].imageUrl !='') {

				// 	// }
				// }


			},
			time() {
				let currentDate = new Date();
				let year = currentDate.getFullYear();
				let month = currentDate.getMonth() + 1; // 注意月份从0开始，需要加1
				let day = currentDate.getDate();

				// 输出当前日期
				//console.log(year + "-" + month + "-" + day);
				this.time1 = year + "-" + month + "-" + day
			},
			handleClose() {
				this.dialogVisible = false
				this.dialogVisible1 = false
			},
			// 调用权限（打开摄像头功能）
			getCompetence() {
				var _this = this;
				_this.thisCancas = document.getElementById("canvasCamera");
				_this.thisContext = this.thisCancas.getContext("2d");
				_this.thisVideo = document.getElementById("videoCamera");
				_this.thisVideo.style.display = "block";
				// 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
				if (navigator.mediaDevices === undefined) {
					navigator.mediaDevices = {};
				}
				// 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
				// 使用getUserMedia，因为它会覆盖现有的属性。
				// 这里，如果缺少getUserMedia属性，就添加它。
				if (navigator.mediaDevices.getUserMedia === undefined) {
					navigator.mediaDevices.getUserMedia = function(constraints) {
						// 首先获取现存的getUserMedia(如果存在)
						var getUserMedia =
							navigator.webkitGetUserMedia ||
							navigator.mozGetUserMedia ||
							navigator.getUserMedia;
						// 有些浏览器不支持，会返回错误信息
						// 保持接口一致
						if (!getUserMedia) {
							//不存在则报错
							return Promise.reject(
								new Error("getUserMedia is not implemented in this browser")
							);
						}
						// 否则，使用Promise将调用包装到旧的navigator.getUserMedia
						return new Promise(function(resolve, reject) {
							getUserMedia.call(navigator, constraints, resolve, reject);
						});
					};
				}
				var constraints = {
					audio: false,
					video: {
						width: this.videoWidth,
						height: this.videoHeight,
						transform: "scaleX(-1)",
					},
				};
				navigator.mediaDevices
					.getUserMedia(constraints)
					.then(function(stream) {
						// 旧的浏览器可能没有srcObject
						if ("srcObject" in _this.thisVideo) {
							_this.thisVideo.srcObject = stream;
						} else {
							// 避免在新的浏览器中使用它，因为它正在被弃用。
							_this.thisVideo.src = window.URL.createObjectURL(stream);
						}
						_this.thisVideo.onloadedmetadata = function(e) {
							_this.thisVideo.play();
							_this.show1 = false
						};
					})
					.catch((err) => {
						console.log(err);
					});
			},
			open_camera(e, id) {
				var _this = this
				console.log(e)
				if (id == 0) {
					this.camera = false
					this.camera_show = false
				} else {
					this.camera = true
				}
				// if()
				if (!e.value) {
					this.dialogVisible1 = true
					setTimeout(() => {
						if (_this.show1) {
							_this.getCompetence()
						}
					}, 500)
				}

			},
			//  绘制图片（拍照功能）
			setImage() {
				var _this = this;
				// canvas画图
				_this.thisContext.drawImage(
					_this.thisVideo,
					0,
					0,
					_this.videoWidth,
					_this.videoHeight
				);
				// 获取图片base64链接
				var image = this.thisCancas.toDataURL("image/png");
				console.log(image)
				var file = _this.dataURLtoFile(image, 143)
				//console.log(file)
				//_this.imgSrc = image; //赋值并预览图片
				this.img_list1[0].hege[this.index].imagePath = image
				//console.log(this.img_list[this.index].imagePath)
				//this.imageUrl = data.file
				this.img_list1[0].hege[this.index].imageUrl = URL.createObjectURL(file);
				this.img_list1[0].hege[this.index].srcList[0] = URL.createObjectURL(file);
				this.srcList.push(URL.createObjectURL(file))
				if (this.img_list1[0].hege[this.index].imageUrl != '') {
					this.img_list1[0].hege[this.index].value = true
				}
				console.log(this.img_list1[0])
				var list = {
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}
				if (this.index == this.img_list1[0].hege.length - 1 && this.img_list1[0].hege[this.index].imageUrl !=
					'') {
					this.img_list1[0].hege.push(list)
				}
				this.camera_show = true
				//this.dialogVisible1 = false
			},

			setImage2() {
				this.index += 1
				var _this = this;
				// canvas画图
				_this.thisContext.drawImage(
					_this.thisVideo,
					0,
					0,
					_this.videoWidth,
					_this.videoHeight
				);
				// 获取图片base64链接
				var image = this.thisCancas.toDataURL("image/png");
				//console.log(image)
				var file = _this.dataURLtoFile(image, 143)
				//console.log(file)
				//_this.imgSrc = image; //赋值并预览图片
				this.img_list1[0].hege[this.index].imagePath = image
				//console.log(this.img_list[this.index].imagePath)
				//this.imageUrl = data.file
				this.img_list1[0].hege[this.index].imageUrl = URL.createObjectURL(file);
				this.img_list1[0].hege[this.index].srcList[0] = URL.createObjectURL(file);
				this.srcList.push(URL.createObjectURL(file))
				if (this.img_list1[0].hege[this.index].imageUrl != '') {
					this.img_list1[0].hege[this.index].value = true
				}

				var list = {
					imageUrl: '',
					imagePath: '',
					value: false,
					srcList: [],
				}
				if (this.index == this.img_list1[0].hege.length - 1 && this.img_list1[0].hege[this.index].imageUrl !=
					'') {
					this.img_list1[0].hege.push(list)
				}

				//this.dialogVisible1 = false
			},

			setImage1() {
				var _this = this;
				// canvas画图
				_this.thisContext.drawImage(
					_this.thisVideo,
					0,
					0,
					_this.videoWidth,
					_this.videoHeight
				);
				// 获取图片base64链接
				var image = this.thisCancas.toDataURL("image/png");
				//console.log(image)
				var file = _this.dataURLtoFile(image, 143)
				this.img_list1[this.index].imagePath = image
				//console.log(this.img_list1[this.index].imagePath)
				//this.imageUrl = data.file
				this.img_list1[this.index].imageUrl = URL.createObjectURL(file);
				this.img_list1[this.index].srcList[0] = URL.createObjectURL(file);
				this.srcList1.push(URL.createObjectURL(file))
				if (this.img_list1[this.index].imageUrl != '') {
					this.img_list1[this.index].value = true
				}

				this.dialogVisible1 = false

				if (this.img_list1[this.index].title == '身份证明') {
					this.idCard[0].imageUrl = URL.createObjectURL(file);
					this.idCard[0].imagePath = image;
					this.idCard[0].value = true
					this.idCard[0].srcList = URL.createObjectURL(file);
				} else if (this.img_list1[this.index].title == '') {
					this.idCard[1].imageUrl = URL.createObjectURL(file);
					this.idCard[1].imagePath = image
					this.idCard[1].value = true
					this.idCard[1].srcList = URL.createObjectURL(file);
				} else if (this.img_list1[this.index].title == '营业执照') {
					this.businesslicense.imageUrl = URL.createObjectURL(file);
					this.businesslicense.imagePath = image
					this.businesslicense.value = true
					this.businesslicense.srcList = URL.createObjectURL(file);
				}
			},
			// 关闭摄像头
			stopNavigator() {
				this.thisVideo.srcObject.getTracks()[0].stop();
			},
			// base64转文件，此处没用到
			dataURLtoFile(dataurl, filename) {
				// var arr = dataurl.split(",");
				// var mime = arr[0].match(/:(.*?);/)[1];
				// var bstr = atob(arr[1]);
				// var n = bstr.length;
				// var u8arr = new Uint8Array(n);
				// while (n--) {
				// 	u8arr[n] = bstr.charCodeAt(n);
				// }
				// return new File([u8arr], filename, {
				// 	type: mime
				// });
				// 将base64的数据部分提取出来
				const arr = dataurl.split(',');
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);

				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}

				// 将Uint8Array转换为Blob对象
				const blob = new Blob([u8arr], {
					type: mime
				});

				// 创建File对象
				const file = new File([blob], filename, {
					type: mime
				});

				return file;
			},



			identify() {
				var _this = this
				if (this.img_list1[0].hege[0].imageUrl == '' || this.img_list1[1].imageUrl == '' || this.img_list1[2]
					.imageUrl == '' || this.img_list1[3].imageUrl == '') {
					this.dialogVisible = true
				} else {
					//this.$router.push('/identify_details')
					this.img_list1[0].hege.splice(this.img_list1[0].hege.length - 1, 1)
					if (this.current == 0) {
						if (this.img_list1[this.img_list1.length - 1].imageUrl == '') {
							var list1 = [{
								'车用气瓶': '车用气瓶',
								'产品名称': '',
								'充装介质': '',
								'工作压力': '',
								'气瓶容积': '',
								'制作单位名称': '',
								'制造日期': '',
								'产品编号': '',
							}, {
								'姓名': '',
								'住址': '',
								'公民身份号码': '',
								'邮政编码': '052160',
								'有效期限': '',
							}, {
								'车牌号': '',
								'车辆识别代号': '',
							}, {
								'单位内编号': '',
								'气瓶数量': '',
								'安装单位': '',
								'安全管理员': '',
								'投入使用日期': this.time1,
								'移动电话': '',
							}]
						} else {
							var list1 = [{
								'车用气瓶': '车用气瓶',
								'产品名称': '',
								'充装介质': '',
								'工作压力': '',
								'气瓶容积': '',
								'制作单位名称': '',
								'制造日期': '',
								'产品编号': '',
							}, {
								'姓名': '',
								'住址': '',
								'公民身份号码': '',
								'邮政编码': '052160',
								'有效期限': '',
							}, {
								'车牌号': '',
								'车辆识别代号': '',
							}, {
								'单位内编号': '',
								'气瓶数量': '',
								'投入使用日期': this.time1,
								'移动电话': '',
							}, {
								'安装单位': '',
								'安全管理员': '',
							}]
						}

					} else if (this.current == 1) {
						if (this.img_list1[this.img_list1.length - 1].imageUrl == '') {
							var list1 = [{
								'车用气瓶': '车用气瓶',
								'产品名称': '',
								'充装介质': '',
								'工作压力': '',
								'气瓶容积': '',
								'制作单位名称': '',
								'制造日期': '',
								'产品编号': '',
							}, {
								'名称': '',
								'住所': '',
								'统一社会信用代码': '',
								'邮政编码': '052160',
							}, {
								'车牌号': '',
								'车辆识别代号': '',
							}, {
								'单位内编号': '',
								'气瓶数量': '',
								'安装单位': '',
								'安全管理员': '',
								'投入使用日期': this.time1,
								'移动电话': '',
							}]
						} else {
							var list1 = [{
								'车用气瓶': '车用气瓶',
								'产品名称': '',
								'充装介质': '',
								'工作压力': '',
								'气瓶容积': '',
								'制作单位名称': '',
								'制造日期': '',
								'产品编号': '',
							}, {
								'名称': '',
								'住所': '',
								'统一社会信用代码': '',
								'邮政编码': '052160',
							}, {
								'机动车登记编号': '',
								'车辆识别代号': '',
							}, {
								'单位内编号': '',
								'气瓶数量': '',
								'投入使用日期': this.time1,
								'移动电话': '',
							}, {
								'安装单位': '',
								'安全管理员': '',
							}]
						}

					}
					if (this.img_list1[this.img_list1.length - 1].imageUrl == '') {
						this.img_list1.splice(this.img_list1.length - 1, 1)
					}
					if (this.current == 0) {
						var list = {
							title: '身份证明',
							hege: [{
								imageUrl: _this.img_list1[1].imageUrl,
								imagePath: _this.img_list1[1].imagePath,
								value: _this.img_list1[1].value,
								srcList: _this.img_list1[1].srcList,
							}, {
								imageUrl: _this.img_list1[2].imageUrl,
								imagePath: _this.img_list1[2].imagePath,
								value: _this.img_list1[2].value,
								srcList: _this.img_list1[2].srcList,
							}]
						}
						this.img_list1[1] = list
						this.img_list1.splice(2, 1)
					}
					this.$router.push({
						path: "/identify_details",
						query: {
							list1: list1,
							img_list1: JSON.stringify(this.img_list1),
							name: '特种设备使用登记'
						}
					})
				}
			},
			select(e, index) {
				if (e == 0) {
					this.current = 0
					this.img_list1[index].title = "身份证明"
					this.img_list1[index].imageUrl = this.idCard[0].imageUrl
					this.img_list1[index].imagePath = this.idCard[0].imagePath
					this.img_list1[index].value = this.idCard[0].value
					this.img_list1[index].srcList = this.idCard[0].srcList
					var list = {
						title: '',
						imageUrl: this.idCard[1].imageUrl,
						imagePath: this.idCard[1].imagePath,
						value: this.idCard[1].value,
						srcList: this.idCard[1].srcList,
					}
					if (this.img_list1[index + 1].title != '') {
						this.img_list1.splice(index + 1, 0, list)
					}

				} else if (e == 1) {
					this.current = 1
					this.img_list1[index].title = "营业执照"
					this.img_list1[index].imageUrl = this.businesslicense.imageUrl
					this.img_list1[index].imagePath = this.businesslicense.imagePath
					this.img_list1[index].value = this.businesslicense.value
					this.img_list1[index].srcList = this.businesslicense.srcList

					if (this.img_list1[index + 1].title == '') {
						this.img_list1.splice(index + 1, 1)
					}



				}
			},
			updata(e) {
				this.index = e
				this.img_list1[0].hege[e].value = false //开启图片上传
				setTimeout(() => {
					this.img_list1[0].hege[e].value = true //开启图片上传
				}, 300)

			},
			updata1(e) {
				console.log(13)
				this.index = e
				this.img_list1[e].value = false //开启图片上传
				setTimeout(() => {
					this.img_list1[e].value = true //开启图片上传
				}, 300)

			},
			getindex(e) {
				this.index = e
			},
			httprequest() {},
			//获取图片路径
			convertToBase64(file) {
				if (file) {
					const reader = new FileReader();
					reader.onload = (e) => {
						this.base64String = e.target.result;
					};
					reader.readAsDataURL(file);
				}
			},
			getBase64(file) {
				console.log(111)
				return new Promise((resolve, reject) => {
					let reader = new FileReader()
					reader.readAsDataURL(file)
					reader.onload = function() {
						resolve(reader.result)
					}
					reader.onerror = function(error) {
						reject(error)
					}
				})
			},
			beforeAvatarUpload(file) {
				setTimeout(() => {
					console.log(file)
					const isJPG = file.type === 'image/jpeg';
					const isPNG = file.type === 'image/png';
					if (!isJPG && !isPNG) {
						this.$message.error('只能上传图片!');
					} else {
						this.getBase64(file).then(res => {
							console.log(res)

							//this.img_list1[this.index].hege[this.index].imagePath = res
							this.img_list1[0].hege[this.index].imagePath = res
							this.img_list1[0].hege[this.index].imageUrl = URL.createObjectURL(file);
							this.img_list1[0].hege[this.index].srcList[0] = URL.createObjectURL(file);
							this.srcList.push(URL.createObjectURL(file))
							if (this.img_list1[0].hege[this.index].imageUrl != '') {
								this.img_list1[0].hege[this.index].value = true
							}
							var list = {
								imageUrl: '',
								imagePath: '',
								value: false,
								srcList: [],
							}
							if (this.index == this.img_list1[0].hege.length - 1 && this.img_list1[0].hege[
									this.index]
								.imageUrl !=
								'') {
								this.img_list1[0].hege.push(list)
							}
						})


					}
				}, 1000)

			},
			beforeAvatarUpload1(file) {
				console.log(file)
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				if (!isJPG && !isPNG) {
					this.$message.error('只能上传图片!');
				} else {
					this.getBase64(file).then(res => {
						this.img_list1[this.index].imagePath = res
						//console.log(this.img_list1[this.index].imagePath)
						//this.imageUrl = data.file
						this.img_list1[this.index].imageUrl = URL.createObjectURL(file);
						this.img_list1[this.index].srcList[0] = URL.createObjectURL(file);
						this.srcList1.push(URL.createObjectURL(file))
						if (this.img_list1[this.index].imageUrl != '') {
							this.img_list1[this.index].value = true
						}

						if (this.img_list1[this.index].title == '身份证明') {
							this.idCard[0].imageUrl = res
							this.idCard[0].imagePath = URL.createObjectURL(file);
							this.idCard[0].value = true
							this.idCard[0].srcList = URL.createObjectURL(file);
						} else if (this.img_list1[this.index].title == '') {
							this.idCard[1].imageUrl = res
							this.idCard[1].imagePath = URL.createObjectURL(file);
							this.idCard[1].value = true
							this.idCard[1].srcList = URL.createObjectURL(file);
						} else if (this.img_list1[this.index].title == '营业执照') {
							this.businesslicense.imageUrl = res
							this.businesslicense.imagePath = URL.createObjectURL(file);
							this.businesslicense.value = true
							this.businesslicense.srcList = URL.createObjectURL(file);
						}
					})


					// this.img_list1[this.index].imagePath = 
					// //console.log(this.img_list1[this.index].imagePath)
					// //this.imageUrl = data.file
					// this.img_list1[this.index].imageUrl = URL.createObjectURL(file);
					// this.img_list1[this.index].srcList[0] = URL.createObjectURL(file);
					// if (this.img_list1[this.index].imageUrl != '') {
					// 	this.img_list1[this.index].value = true
					// }
					// this.dialogVisible1 = false

				}
			},
			image_close(e) {
				this.img_list1[0].hege.splice(e, 1)
				this.srcList.splice(e, 1)
			},
			image_close1(e) {
				this.img_list1[e].imageUrl = ''
				this.img_list1[e].imagePath = ''
				this.img_list1[e].value = false
				this.img_list1[e].srcList = []
				
				// if (this.img_list1[this.index].title == '身份证明') {
				// 	this.idCard[0].imageUrl = URL.createObjectURL(file);
				// 	this.idCard[0].imagePath = image;
				// 	this.idCard[0].value = true
				// 	this.idCard[0].srcList = URL.createObjectURL(file);
				// } else if (this.img_list1[this.index].title == '') {
				// 	this.idCard[1].imageUrl = URL.createObjectURL(file);
				// 	this.idCard[1].imagePath = image
				// 	this.idCard[1].value = true
				// 	this.idCard[1].srcList = URL.createObjectURL(file);
				// } else if (this.img_list1[this.index].title == '营业执照') {
				// 	this.businesslicense.imageUrl = URL.createObjectURL(file);
				// 	this.businesslicense.imagePath = image
				// 	this.businesslicense.value = true
				// 	this.businesslicense.srcList = URL.createObjectURL(file);
				// }
				if (this.img_list1[e].title == '身份证明') {
					this.idCard[0].imageUrl = ''
					this.idCard[0].imagePath = ''
					this.idCard[0].value = false
					this.idCard[0].srcList = []
				} else if (this.img_list1[e].title == '') {
					this.idCard[1].imageUrl = ''
					this.idCard[1].imagePath = ''
					this.idCard[1].value = false
					this.idCard[1].srcList = []
				} else if (this.img_list1[e].title == '营业执照') {
					this.businesslicense.imageUrl = ''
					this.businesslicense.imagePath = ''
					this.businesslicense.value = false
					this.businesslicense.srcList = []
				}
				this.srcList1.splice(e, 1)
			},
			open(e) {
				if (this.img_list1[0].hege[e].imageUrl == '') {
					this.img_list1[0].hege[e].value = false //开启图片上传
				}
			},
			open1(e) {
				if (this.img_list1[e].imageUrl == '') {
					this.img_list1[e].value = false //开启图片上传
				}
			},
		},

	}
</script>

<style lang="less" scoped>
	.wrap {
		width: 19.98rem;
		height: calc(100vh - 1.45rem);
		margin-top: 0.27rem;
		margin-left: 0.37rem;
		background-color: #fff;
		border-radius: 0.1rem;
		overflow-y: auto;
		scrollbar-width: none;
		/* 对于 Firefox 隐藏滚动条 */
	}

	.wrap::-webkit-scrollbar {
		display: none;
		/* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
	}

	// .wrap {
	// 	//background-color: #000;
	// 	width: 100vw;
	// 	height: calc(100vh - 0.71rem);
	// 	background-image: url('../assets/image/index1.png');
	// 	background-size: 100% 100%;
	// 	background-position: center center;
	// 	background-repeat: no-repeat;
	// 	overflow-y: auto;
	// }

	.my_template {
		font-weight: bold;
		font-size: 0.24rem;
		color: #030A1A;
	}

	/deep/.el-upload-dragger {
		width: 1.59rem;
		height: 0.54rem;
		background: #F6990E;
		//margin-top: 0.29rem;
		border: none;
	}

	.avatar-uploader {
		width: 3.73rem;
		height: 2.31rem;
		margin-top: 0.29rem;
		text-align: center;
		border: 2px dashed #7B8391;
		position: relative;
	}

	.avatar-uploader1 {
		width: 3.73rem;
		height: 2.31rem;
		margin-top: 0.29rem;
		text-align: center;
		//border: 2px dashed #7B8391;
		position: relative;
		background-color: #EBEEF2;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 3.73rem;
		height: 2.31rem;
		line-height: 2.31rem;
		text-align: center;
	}


	// /deep/.el-upload-dragger {
	// 	background-color: transparent;
	// 	border: 2px dashed #7B8391;
	// }

	.select {
		width: 1.15rem;
		height: 0.44rem;
		background: #DEE6FF;
		font-weight: 400;
		font-size: 0.2rem;
		color: #2C60F5;
		text-align: center;
		line-height: 0.44rem;
	}

	.select1 {
		width: 1.15rem;
		height: 0.44rem;
		background: #2C60F5;
		font-weight: 400;
		font-size: 0.2rem;
		color: #FFFFFF;
		text-align: center;
		line-height: 0.44rem;
	}

	/deep/.el-dialog__headerbtn .el-dialog__close {
		font-size: 0.35rem;
	}

	.file-upload-wrapper {
		/* 添加你的自定义样式 */
		// padding: 10px;
		//border: 1px solid #ccc;
		border-radius: 5px;
		display: inline-block;
	}

	.file-upload-wrapper button {
		// padding: 5px 10px;
		// background-color: #007bff;
		// color: white;
		border: none;
		// border-radius: 5px;
		// cursor: pointer;

		width: 1.59rem;
		height: 0.54rem;
		background: #F6990E;
		color: #fff;
		font-size: 0.2rem;
		border-radius: 0;
	}

	.file-upload-wrapper button:hover {
		//background-color: #0056b3;
	}
</style>