<template>
	<div class="wrap">
		<div style="display: flex;align-items: center;margin-top: 0.48rem;">
			<img style="width: 0.27rem;height: 0.27rem;" src="../assets/image/index2.png" alt="" srcset="" />
			<div class="my_template">我的收藏</div>
		</div>
		
		<div class="my_template_text">从手工录入到智能识别的信息革命之旅</div>
		<div class="wrap1">
			<div style="display: flex;justify-content: space-between;flex-wrap: wrap;">
				<div v-for="(item,index) in list" style="width: 10.13rem;height: 2.38rem;background: #FFFFFF;box-shadow: 0rem 0rem 0.25rem 0.01rem rgba(221,226,236,0.65);margin-bottom: 0.2rem;display: flex;align-items: center;position: relative;">
					<div style="position: relative;">
						<img style="margin-left: 0.28rem;width: 3rem;height: 1.88rem;" :src="item.img" alt="" />
						<img @click="recommend(index)" v-if="!item.show" style="position: absolute;top: 0.07rem;right: 0.07rem;width: 0.55rem;height: 0.55rem;" src="../assets/image/recommend9.png" alt="" />
						<img @click="recommend(index)" v-if="item.show" style="position: absolute;top: 0.07rem;right: 0.07rem;width: 0.55rem;height: 0.55rem;" src="../assets/image/recommend10.png" alt="" />
					</div>
					<div style="padding: 0 0.85rem 0 0.3rem;height: 1.88rem;">
						<div style="font-weight: bold;font-size: 0.28rem;color: #0F131A;margin-top: 0.26rem;">{{item.title}}</div>
						<div style="font-weight: 400;font-size: 0.18rem;color: #7B8391;margin-top: 0.1rem;">{{item.text}}</div>
					</div>
					<img style="position: absolute;bottom: 0;right: 0;width: 0.55rem;height: 0.55rem;" src="../assets/image/recommend8.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	//万物识别
	import '../assets/icon/iconfont.css'
	export default {
		data() {
			return {
				list:[{
					title:'身份证识别',
					show:false,
					text:'对身份证正反面的全部字段进行结构化识别，包括姓名、性别、民族、出生日期、地址、身份证号、有效期限、签发机关。',
					img:require("@/assets/image/recommend2.png"),
				},{
					title:'银行卡识别',
					show:false,
					text:'支持对主流银行卡6个关键字段的高精度结构化识别，包括类型、发卡机构、发卡机构代号、有效期、卡号、持有人。',
					img:require("@/assets/image/recommend3.png"),
				},{
					title:'驾驶证识别',
					show:false,
					text:'支持对驾驶证正副页全部字段进行结构化识别，包括姓名、类型、驾驶证证号、性别、国籍、住址、出生日期、初次领证日期、准驾车型、有限期限始（至）、总计有效期限。',
					img:require("@/assets/image/recommend4.png"),
				},{
					title:'行驶证识别',
					show:false,
					text:'实现对机动车行驶证正副页中关键字段进行结构化高精准识别，包括号牌号码、车辆类型、所有人、车辆识别代码、发证日期、检验有效期等。',
					img:require("@/assets/image/recommend5.png"),
				},{
					title:'社保卡识别',
					show:false,
					text:'支持对全国各大省市社保卡全部字段结构化识别服务，包括姓名、社会保障号码、卡号、发卡日期、芯片下方号码。',
					img:require("@/assets/image/recommend6.png"),
				},{
					title:'营业执照识别',
					show:false,
					text:'可快速精准结构化识别企业营业执照中的注册号、公司名称、地址、主体类型、法定代表人等。',
					img:require("@/assets/image/recommend7.png"),
				}]
			}
		},
		created() {

		},
		watch: {

		},
		mounted() {

		},
		computed: {

		},
		methods: {
			recommend(e){
				this.list[e].show = !this.list[e].show
			},
			page1(e) {
				console.log(e)
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		},

	}
</script>

<style lang="less" scoped>
	.wrap {
		width: 20.61rem;
		//height: calc(100vh - 2.98rem);
		margin-left: 0.37rem;
		//background-color: #fff;
		/* 对于 Firefox 隐藏滚动条 */
	}

	.wrap1::-webkit-scrollbar {
		display: none;
		/* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
	}

	.wrap1 {
		width: 20.61rem;
		height: calc(100vh - 2.5rem);
		//background-color: #fff;
		//border-radius: 0.1rem;
		overflow-y: auto;
		margin-top: 0.36rem;
		scrollbar-width: none;
		/* 对于 Firefox 隐藏滚动条 */
	}
	
	.my_template {
		font-weight: bold;
		font-size: 0.24rem;
		color: #030A1A;
	}
	
	.my_template_text {
		font-weight: 400;
		font-size: 0.2rem;
		color: #7B8391;
		margin-top: 0.09rem;
	}
</style>