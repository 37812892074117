<template>
	<div class="wrap">
		<div style="display: flex;align-items: center;margin-top: 0.48rem;">
			<img style="width: 0.27rem;height: 0.27rem;" src="../assets/image/index2.png" alt="" srcset="" />
			<div class="my_template">历史识别</div>
		</div>

		<div class="my_template_text">从手工录入到智能识别的信息革命之旅</div>
		<div class="wrap1">
			<div
				style="display: flex;justify-content: space-between;align-items: center;margin-top: 0.29rem;margin: 0.29rem 0.66rem 0 0.66rem;">
				<div style="display: flex;align-items: center;">
					<div style="margin-right: 0.26rem;color: #0F131A;font-size: 0.18rem;">识别日期</div>
					<el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
					</el-date-picker>
				</div>
				<div style="display: flex;">
					<div
						style="width: 1.38rem;height: 0.55rem;border: 0.01rem solid #2C60F5;text-align: center;line-height: 0.55rem;font-size: 0.2rem;color: #2C60F5;">
						批量下载</div>
					<div
						style="width: 1.38rem;height: 0.55rem;background: #DF2525;text-align: center;line-height: 0.55rem;font-size: 0.2rem;color: #fff;margin-left: 0.15rem;">
						批量删除</div>
				</div>
			</div>
			<div style="margin: 0.1rem 0.75rem 0 0.75rem;">
				<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
					@selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column label="名称" width="320">
						<template slot-scope="scope">
							<div style="display: flex;align-items: center;">
								<img style="width: 0.3rem;height: 0.3rem;" src="../assets/image/record2.png" alt="" />
								<span style="margin-left: 10px">{{ scope.row.date }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="时间" width="220">
					</el-table-column>
					<el-table-column label="操作员" width="220">
						<template slot-scope="scope">
							<div style="display: flex;align-items: center;">
								<img style="width: 0.3rem;height: 0.3rem;" src="../assets/image/record1.png" alt="" />
								<span style="margin-left: 10px">{{ scope.row.date }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="拍照/上传照片" width="300">
						<template slot-scope="scope">
							<div style="display: flex;">
								<div
									style="width: 0.85rem;height: 0.38rem;background: #F6990E;border-radius: 0.05rem;text-align: center;line-height: 0.38rem;color: #fff;font-size: 0.18rem;">
									查看</div>
								<div
									style="width: 1.4rem;height: 0.38rem;background: #2C60F5;border-radius: 0.05rem;text-align: center;line-height: 0.38rem;color: #fff;font-size: 0.18rem;margin-left: 0.18rem;">
									下载图片包zip</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="文档识别" width="300">
						<template slot-scope="scope">
							<div style="display: flex;">
								<div
									style="width: 0.85rem;height: 0.38rem;background: #F6990E;border-radius: 0.05rem;text-align: center;line-height: 0.38rem;color: #fff;font-size: 0.18rem;">
									预览打印</div>
								<div
									style="width: 1.4rem;height: 0.38rem;background: #2C60F5;border-radius: 0.05rem;text-align: center;line-height: 0.38rem;color: #fff;font-size: 0.18rem;margin-left: 0.18rem;">
									下载</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<div>
								<img style="width: 0.3rem;height: 0.3rem;" src="../assets/image/record3.png" alt="" />
							</div>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination style="display: flex;justify-content: center;width: 100%;margin-top: 0.3rem;"
					:current-page.sync="page" @current-change="page1" background pager-count='13'
					layout="prev, pager, next" :total="1000">
				</el-pagination>
			</div>

		</div>
	</div>
</template>

<script>
	//万物识别
	import '../assets/icon/iconfont.css'
	export default {
		data() {
			return {
				page: 1,
				tableData: [{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-08',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-06',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-07',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-07',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-07',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-07',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
				multipleSelection: [],
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				value2: ''
			}
		},
		created() {

		},
		watch: {

		},
		mounted() {

		},
		computed: {

		},
		methods: {
			page1(e) {
				console.log(e)
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		},

	}
</script>

<style lang="less" scoped>
	.wrap {
		width: 20.61rem;
		//height: calc(100vh - 2.98rem);
		margin-left: 0.37rem;
		//background-color: #fff;
		/* 对于 Firefox 隐藏滚动条 */
	}

	.wrap1::-webkit-scrollbar {
		display: none;
		/* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
	}

	.wrap1 {
		width: 20.61rem;
		height: calc(100vh - 2.7rem);
		background-color: #fff;
		border-radius: 0.1rem;
		overflow-y: auto;
		margin-top: 0.36rem;
		scrollbar-width: none;
		/* 对于 Firefox 隐藏滚动条 */
	}

	.my_template {
		font-weight: bold;
		font-size: 0.24rem;
		color: #030A1A;
	}

	.my_template_text {
		font-weight: 400;
		font-size: 0.2rem;
		color: #7B8391;
		margin-top: 0.09rem;
	}

	.el-table {
		font-size: 0.18rem;
	}

	/deep/.el-table .el-table__cell {
		padding: 0;
		height: 0.63rem;
	}

	/deep/.el-pagination.is-background .el-pager li {
		width: 0.4rem;
		height: 0.4rem;
		line-height: 0.4rem;
		font-size: 0.16rem;
	}

	/deep/.el-pagination.is-background .btn-prev {
		width: 0.4rem;
		height: 0.4rem;
		line-height: 0.4rem;
		font-size: 0.16rem;
	}
	/deep/.el-pagination.is-background .btn-next{
		width: 0.4rem;
		height: 0.4rem;
		line-height: 0.4rem;
		font-size: 0.16rem;
	}
</style>